// Frameworks
import React from 'react';

// Material UI
import Typography from '@mui/material/Typography';

// App Components
import SEO from '../components/seo';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] Settings');
log.debug('initialized');


const Settings = ({ location }) => {
  return (
    <>
      <SEO title={'Settings'} />

      <Typography variant="h6" align="center">Carbon Opus Settings Management</Typography>

      <p>...</p>
    </>
  );
};

export default Settings;
