// Frameworks
import React, { useState, useEffect, useCallback } from 'react';
import equal from 'fast-deep-equal';
import _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

// App Components
import { Helpers } from '../../utils/helpers';
import { GLOBALS } from '../../utils/globals';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Upload/Pricing');
log.debug('initialized');


const UploadPricing = ({
  onChange = _.noop,
  displayErrors = false,
  formData: existingFormData,
}) => {
  const [ audioState, setAudioState ] = useState(existingFormData);

  const _isValidSalePrice = useCallback(() => {
    return (GLOBALS.SALE_PRICE_MIN <= audioState.salePrice && audioState.salePrice <= GLOBALS.SALE_PRICE_MAX);
  }, [ audioState.salePrice ]);

  useEffect(() => {
    if (!equal(existingFormData, audioState)) {
      const isValid = _isValidSalePrice();
      onChange({ isValid, data: audioState });
    }
  }, [ audioState, existingFormData, _isValidSalePrice, onChange ]);

  const _getPricingSplit = () => {
    const protocolPct = GLOBALS.PROTOCOL_PERCENT;
    const resellerPct = (audioState.resaleRoyalty / 100);

    let protocolShare = _.round(audioState.salePrice * protocolPct, 8);
    if (protocolShare < 0.01) { protocolShare = 0.01; } // Min $0.01 per sale

    const resellerShare = _.floor(audioState.salePrice * resellerPct, 8);
    const creatorShare = _.floor(audioState.salePrice - protocolShare - resellerShare, 8);

    return {
      creator: Helpers.formatCurrency(creatorShare),
      reseller: Helpers.formatCurrency(resellerShare),
      protocol: Helpers.formatCurrency(protocolShare),
    };
  };

  const _updateSalePrice = (event, newValue) => {
    setAudioState(oldState => ({
      ...oldState,
      salePrice: newValue,
    }));
  };

  const _updateResaleRoyalty = (event, newValue) => {
    setAudioState(oldState => ({
      ...oldState,
      resaleRoyalty: newValue,
    }));
  };


  const pricingSplit = _getPricingSplit();

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Box mb={1}>
          <Typography variant="overline">Sale Price</Typography>
        </Box>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9}>
            <Slider
              value={audioState.salePrice}
              min={GLOBALS.SALE_PRICE_MIN}
              max={GLOBALS.SALE_PRICE_MAX}
              step={0.01}
              valueLabelDisplay="off"
              onChange={_updateSalePrice}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>{Helpers.formatCurrency(audioState.salePrice)}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box mb={1}>
          <Typography variant="overline">Reseller Percent</Typography>
        </Box>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={9}>
            <Slider
              value={audioState.resaleRoyalty}
              min={GLOBALS.RESALE_ROYALTY_MIN}
              max={GLOBALS.RESALE_ROYALTY_MAX}
              step={0.5}
              valueLabelDisplay="off"
              onChange={_updateResaleRoyalty}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>{audioState.resaleRoyalty}%</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box mb={1}>
          <Typography variant="overline">On every sale:</Typography>
          <Typography variant="body1">Creator gets {pricingSplit.creator}</Typography>
          <Typography variant="body1">Reseller gets {pricingSplit.reseller}</Typography>
          <Typography variant="body1">Protocol gets {pricingSplit.protocol} ({(GLOBALS.PROTOCOL_PERCENT * 100)}%)</Typography>
        </Box>
      </Grid>

    </Grid>
  );
};

export { UploadPricing };
