// Frameworks
import React from 'react';
import { navigate } from 'gatsby';

// App Components
import Logo from '../../images/logo/CarbonOpus_logo_black.svg';
import LogoText from '../../images/logo/CarbonOpus_text_black.svg';

// Material UI
import Grid from '@mui/material/Grid';

// Common
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('AppTitleLink');
log.debug('initialized');

const AppTitleLink = () => {
  const _goHome = () => {
    navigate(GLOBALS.APP_ROOT);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Logo onClick={_goHome} style={{ maxWidth: 64, width: 32, height: 'auto', marginTop: 2, marginRight: 16 }} />
      </Grid>
      <Grid item>
        <LogoText onClick={_goHome} style={{ maxWidth: 160, width: 160, height: 'auto', marginTop: 2, marginRight: 16 }} />
      </Grid>
    </Grid>
  );
};

export { AppTitleLink };
