// Frameworks
import React, { Fragment } from 'react';
import _ from 'lodash';

// Material UI
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import EditIcon from '@mui/icons-material/Edit';

// App Hooks
import { useAuthContext } from '../../contexts/auth';
import { useMember } from '../../queries/useMember';
import { useMemberPromoted } from '../../hooks/useMemberPromoted';

// App Components
import { AudioDisplay } from '../AudioDisplay';
import { Loading } from '../Loading';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/Promoted');
log.debug('initialized');


const MemberPromoted = ({ memberId }) => {
  const [ authState ] = useAuthContext();
  const { isLoading: isMemberLoading, data: profileMember } = useMember(memberId);
  const { isLoading, data: music } = useMemberPromoted({ memberId: profileMember?.memberId });

  const currentMemberId = _.get(authState, 'user.uid');
  const profileMemberId = profileMember?.memberId;
  const isProfileOwner = profileMemberId === currentMemberId;

  const _renderListItem = (musicInfo, index, isLast = false) => {
    return (
      <Fragment key={index}>
        <AudioDisplay musicId={musicInfo.musicId} variant="item" />
        {!isLast && (<Divider variant="inset" component="li" />)}
      </Fragment>
    );
  };

  if (isLoading || isMemberLoading) {
    return (
      <Loading />
    );
  }

  if (_.isEmpty(music?.collection)) {
    if (isProfileOwner) {
      return <p>TODO: Let user know they can Promote some tracks..</p>;
    }

    // Hide for Public Viewers if No Pinned Tracks
    return null;
  }

  return (
    <Paper elevation={3}>
      <Stack direction="row" justifyContent="space-between" spacing={3}>
        <Typography variant="h6" sx={{ p: 2 }}>
          <FavoriteIcon sx={{ color: red[500], mb: -0.5 }} /> Favorites
        </Typography>
        {
          // isProfileOwner && (
          //   <Button
          //     size="small"
          //     color="secondary"
          //     sx={{ px: 2 }}
          //     startIcon={<EditIcon />}
          //   >
          //     Edit
          //   </Button>
          // )
        }
      </Stack>
      <Divider />
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {_.map(music.collection, (musicInfo, index) => {
          const isLast = music.collection.length === (index + 1);
          return _renderListItem(musicInfo, index, isLast);
        })}
      </List>
    </Paper>
  );
};

export { MemberPromoted };
