// Frameworks
import React from 'react';

// Material UI
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';


// Custom Styles
const containerStyles = {
  // margin: '2rem 0',
  '& span': {
    // marginLeft: '1.4rem',
    fontSize: '1.15rem',
    lineHeight: '2rem',
    verticalAlign: 'super',
  },
};

const Loading = ({ msg = '', size = 20, includeFrame = false, variant = 'default', color = 'primary' }) => {
  const renderWithFrame = () => {
    const isFull = variant === 'full';
    return (
      <Paper elevation={2} sx={{ p: '1rem 1.5rem', mt: isFull ? '12vh' : 0 }}>
        <CircularProgress color={color} size={size} />
        {msg.length ? <span>{msg}</span> : ''}
      </Paper>
    );
  };

  const renderWithoutFrame = () => {
    const isFull = variant === 'full';
    return (
      <Box sx={{ pt: isFull ? '12vh' : 0 }}>
        <CircularProgress color={color} size={size} />
        {msg.length ? <span>{msg}</span> : ''}
      </Box>
    );
  };

  return (
    <Box sx={containerStyles}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {
          includeFrame
            ? renderWithFrame()
            : renderWithoutFrame()
        }
      </Grid>
    </Box>
  );
};

export { Loading };
