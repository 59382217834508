import { ethers, errors } from 'ethers';
import * as _ from 'lodash';

import { CarbonStorage } from '../utils/storage';
import { GLOBALS } from '../utils/globals';

import CarbonEconomicsABI from '../abis/CarbonEconomics.json';
import CarbonOpusABI from '../abis/CarbonOpus.json';
import OpusABI from '../abis/Opus.json';
import UsdcABI from '../abis/FakeUSDC.json';

const _addresses = GLOBALS.CONTRACT_ADDRESS;

// Singleton for accessing the CarbonOpus Contracts
class ContractFactory {
  static __instance = null;

  static instance() {
    if (!this.__instance) {
      this.__instance = new ContractFactory();
    }
    return this.__instance;
  }

  constructor() {
    this.web3 = null;
    this.signer = null;
    this.contracts = {
      carbonOpus: null,
      carbonEconomics: null,
      opus: null,
      usdc: null,
    };
    this.storage = CarbonStorage.fromLocal('pendingTx', {});
  }

  updateWeb3(web3) {
    if (!web3) { return; }
    if (_.isEmpty(_.get(_addresses, `CARBON_OPUS.${web3.chainId}`, ''))) {
      console.error(`[DEV ERROR] CarbonOpus Contract Addresses are "undefined" for Chain: ${web3.chainId}`);
      return;
    }
    this.web3 = web3;
    this.contracts = {
      carbonOpus      : new ethers.Contract(_addresses.CARBON_OPUS[web3.chainId],      CarbonOpusABI),
      carbonEconomics : new ethers.Contract(_addresses.CARBON_ECONOMICS[web3.chainId], CarbonEconomicsABI),
      opus            : new ethers.Contract(_addresses.OPUS[web3.chainId],             OpusABI),
      usdc            : new ethers.Contract(_addresses.USDC[web3.chainId],             UsdcABI),
    };
  }

  updateSigner(web3) {
    if (!web3) { return; }
    if (_.isEmpty(_.get(_addresses, `CARBON_OPUS.${web3.chainId}`, ''))) {
      console.error(`[DEV ERROR] CarbonOpus Contract Addresses are "undefined" for Chain: ${web3.chainId}`);
      return;
    }
    this.signer = web3.signer;
    this.contracts = {
      carbonOpus      : new ethers.Contract(_addresses.CARBON_OPUS[web3.chainId],      CarbonOpusABI,       web3.signer),
      carbonEconomics : new ethers.Contract(_addresses.CARBON_ECONOMICS[web3.chainId], CarbonEconomicsABI,  web3.signer),
      opus            : new ethers.Contract(_addresses.OPUS[web3.chainId],             OpusABI,             web3.signer),
      usdc            : new ethers.Contract(_addresses.USDC[web3.chainId],             UsdcABI,             web3.signer),
    };
  }

  updateTxDispatcher(dispatch) {
    this.txDispatcher = dispatch;
  }

  isDroppedAndReplaced(e) {
    return e?.code === errors.TRANSACTION_REPLACED && e?.replacement && (e?.reason === 'repriced' || e?.cancelled === false);
  }

  async sendTransaction(contractName, contractMethod, txTitle, txOverrides, argsObj = {}) {
    const contract = this.contracts[contractName];
    const dispatch = this.txDispatcher;
    const args = _.values(argsObj);

    if (!contract) {
      dispatch({ type: 'UPDATE_TX_STATE', payload: { status: 'Exception', errors: [
        `Web3 Provider not ready (calling "${contractName}->${contractMethod}")`,
      ] } });
      return;
    }
    if (!contract[contractMethod]) {
      throw new Error(`Invalid Method Name "${contractMethod}" provided to sendTransaction for contract: "${contractName}".`);
    }

    let txData;
    try {
      // Waiting for Signature
      dispatch({ type: 'UPDATE_TX_STATE', payload: { status: 'Pending', txData: {} } });

      if (_.isEmpty(txOverrides)) {
        txOverrides = {
          from: this.signer.address,
          value: 0,
        };
      }

      // Broadcast the Tx
      txData = await contract[contractMethod](...args, txOverrides);
      const storedTx = {
        txTitle,
        hash: txData.hash,
        contractName,
        contractMethod,
        contractAddress: contract.address,
        txOverrides,
        txArgs: argsObj,
      };
      this.storage.set(storedTx);
      return await this.watchExistingTransactions({ ...storedTx, ...txData });

      // Catch any Errors
    } catch (e) {
      const parsedErrorCode = parseInt(e.error?.data?.code ?? e.error?.code ?? e.data?.code ?? e.code);
      const errorCode = isNaN(parsedErrorCode) ? undefined : parsedErrorCode;
      const errorHash = e?.error?.data?.originalError?.data ?? e?.error?.data;
      const errorMessage = e.error?.data?.message ?? e.error?.message ?? e.reason ?? e.data?.message ?? e.message;

      // Was Tx broadcast?
      if (txData) {
        const droppedAndReplaced = this.isDroppedAndReplaced(e);
        if (droppedAndReplaced) {
          const status = e.receipt.status === 0 ? 'Fail' : 'Success';
          dispatch({ type: 'UPDATE_TX_STATE', payload: { status, txData: e.replacement, txReceipt: e.receipt, errors: [
            { errorMessage, errorCode, errorHash },
          ] } });
        } else {
          dispatch({ type: 'UPDATE_TX_STATE', payload: { status: 'Fail', txData, txReceipt: e.receipt, errors: [
            { errorMessage, errorCode, errorHash },
          ] } });
        }
      } else {
        // Tx Failed before being Broadcast
        dispatch({ type: 'UPDATE_TX_STATE', payload: { status: 'Exception', errors: [
          { errorMessage, errorCode, errorHash },
        ] } });
      }
    }
  }

  async watchExistingTransactions(txData) {
    const dispatch = this.txDispatcher;

    let storedTx = {};
    if (_.isEmpty(txData)) {
      storedTx = this.storage.get();
      if (_.isEmpty(storedTx)) { return; }

      txData = await this.web3.readProvider.getTransaction(storedTx.hash);
      if (_.isEmpty(txData)) {
        this.storage.set({});
        return;
      }
    }
    const fullTxData = { ...storedTx, ...txData };

    // Waiting for Tx to be Mined
    dispatch({ type: 'UPDATE_TX_STATE', payload: { status: 'Mining', txData: fullTxData } });
    const txReceipt = await txData.wait();

    // Transaction Mined
    dispatch({ type: 'UPDATE_TX_STATE', payload: { status: 'Success', txData: fullTxData, txReceipt } });
    this.storage.set('');
    return txReceipt;
  }
}

export { ContractFactory };
