// Frameworks
import React from 'react';
import clsx from 'clsx';
import * as _ from 'lodash';

// Material UI
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

// App Components
import { BRANDING } from '../layout/styles/branding';
import UploadIcon from '../../images/upload-icon.svg';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('FileUploadButton');
log.debug('initialized');

// Custom Styles
const useCustomStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
  },
  fileInput: {
    fontSize: 100,
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
  },
  button: {
    color: BRANDING.THEME.COMMON.GHOSTWHITE,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.8rem',
  },
}));

const _getFileSize = (file) => {
  if (!file) { return '0 bytes'; }

  const bytes = file.size;
  const aMultiples = [ 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb' ];
  let sOutput = `${bytes} bytes`;
  for (let nMultiple = 0, nApprox = bytes / 1024; nApprox > 1; nApprox /= 1024, nMultiple++) {
    sOutput = `${nApprox.toFixed(3)} ${aMultiples[nMultiple]}`;
  }
  return sOutput;
};

const _getFileDimensions = (file) => {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = function() {
      const img = new Image();
      img.onload = function() {
        resolve({ width: img.width, height: img.height });
      };
      img.src = fr.result;
    };
    fr.readAsDataURL(file);
  });
};


function FileUploadButton({
  name = 'uploadFile',
  text = 'Upload a file',
  variant = null,
  size = 'large',
  accept = 'image/*',
  multiple = false,
  className = [],
  onFileSelect = _.noop,
}) {
  const customClasses = useCustomStyles();

  const _fileChange = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.target.value;
    const fileObj = event.target.files[0];

    const onFileSelectArgs = {
      file,
      fileObj,
      fileSize: _getFileSize(fileObj),
    };

    if (accept.split('/')[0] === 'image') {
      onFileSelectArgs.fileDimensions = await _getFileDimensions(fileObj);
    }

    onFileSelect(onFileSelectArgs);
  };

  return (
    <div className={clsx(className, customClasses.wrapper)}>
      <Button
        aria-label="Connect Wallet"
        variant={variant}
        size={size}
        startIcon={<UploadIcon />}
        className={customClasses.button}
      >
        {text}
      </Button>
      <input
        type="file"
        name={name}
        multiple={multiple}
        accept={accept}
        className={customClasses.fileInput}
        onChange={_fileChange}
      />
    </div>
  );
}

export { FileUploadButton };
