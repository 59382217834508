import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage, getDownloadURL, ref } from 'firebase/storage';
import { getAnalytics, isSupported } from 'firebase/analytics';
import window from 'global';

// App Components
import { GLOBALS } from '../utils/globals';


// Singleton for accessing the Firebase instance
class CarbonFirebase {
  static __instance = null;

  static _firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MSG_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  };

  static instance() {
    if (!this.__instance) {
      this.__instance = {};
      this.__instance.firebase = initializeApp(this._firebaseConfig);
      this.__instance.firestore = getFirestore(this.__instance.firebase);
      this.__instance.functions = getFunctions(this.__instance.firebase);
      this.__instance.storage = getStorage(this.__instance.firebase);
      this.__instance.auth = getAuth(this.__instance.firebase);
      this.__instance.auth.useDeviceLanguage();

      // Enable Analytics
      this.__instance.analytics = null;
      if (!GLOBALS.IS_DEV && typeof window !== 'undefined') {
        this.__instance.analytics = isSupported()
          .then(yes => yes ? getAnalytics(this.__instance.firebase) : null);
      }

      // Connect to Local Emulators in Development
      if (GLOBALS.IS_DEV) {
        const { connectFirestoreEmulator } = require('firebase/firestore');
        const { connectFunctionsEmulator } = require('firebase/functions');
        const { connectAuthEmulator } = require('firebase/auth');
        const { connectStorageEmulator } = require('firebase/storage');

        connectFirestoreEmulator(this.__instance.firestore, 'localhost', 8080);
        connectFunctionsEmulator(this.__instance.functions, 'localhost', 5001);
        connectAuthEmulator(this.__instance.auth, 'http://localhost:9099');
        connectStorageEmulator(this.__instance.storage, 'localhost', 9199);
      }
    }
    return this.__instance;
  }
}

const _getFirebase = () => CarbonFirebase.instance();
const _getFirestore = () => _getFirebase().firestore;
const _getFunctions = () => _getFirebase().functions;
const _getAnalytics = () => _getFirebase().analytics;
const _getStorage = () => ({ storage: _getFirebase().storage, ref });
const _getStoredImageRef = async (path) => {
  return ref(_getFirebase().storage, path);
};
const _getStoredImage = async (path, hardLink = false) => {
  const imageRef = await _getStoredImageRef(path);
  if (hardLink) {
    const bucket = CarbonFirebase._firebaseConfig.storageBucket;
    return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(imageRef.fullPath)}?alt=media`;
  }
  return await getDownloadURL(imageRef);
};
const _getAuth = () => _getFirebase().auth;


export default CarbonFirebase;
export {
  _getFirebase as getFirebase,
  _getFirestore as getFirestore,
  _getFunctions as getFunctions,
  _getAnalytics as getAnalytics,
  _getStorage as getStorage,
  _getStoredImage as getStoredImage,
  _getStoredImageRef as getStoredImageRef,
  _getAuth as getAuth,
};
