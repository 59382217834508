// Frameworks
import window from 'global';
import _ from 'lodash';

// ChargedOpus Internal Storage
class CarbonStorage {
  static _getItem = (key, initialValue) => () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  };

  static _setItem = (key, initialValue) => (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const oldState = CarbonStorage._getItem(key, initialValue);
      const valueToStore = _.isFunction(value) ? value(oldState) : value;

      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      return valueToStore;
    } catch (error) {
      console.error(error);
      return CarbonStorage._getItem(key, initialValue);
    }
  };

  static fromLocal(key, initialValue) {
    return {
      get: CarbonStorage._getItem(key, initialValue),
      set: CarbonStorage._setItem(key, initialValue),
    };
  }
}

export { CarbonStorage };
