// Frameworks
import * as _ from 'lodash';

export const getChainNameById = (chainId) => {
  switch (_.parseInt(chainId, 10)) {
    case 1:
      return 'mainnet';
    case 3:
      return 'ropsten';
    case 4:
      return 'rinkeby';
    case 5:
      return 'goerli';
    case 42:
      return 'kovan';
    case 137:
      return 'polygon';
    case 80001:
      return 'mumbai';
    case 42161:
      return 'arbitrum';
    case 421613:
      return 'arbitrum-goerli';
    case 10:
      return 'optimism';
    case 420:
      return 'optimism-goerli';
    default:
      return 'development';
  }
};

export const getFriendlyChainNameById = (chainId) => {
  switch (_.parseInt(chainId, 10)) {
    case 1:
      return 'Ethereum Mainnet';
    case 3:
      return 'Ropsten Testnet';
    case 4:
      return 'Rinkeby Testnet';
    case 5:
      return 'Goerli Testnet';
    case 42:
      return 'Kovan Testnet';
    case 137:
      return 'Polygon Mainnet';
    case 80001:
      return 'Mumbai Testnet';
    case 421613:
      return 'Abritrum Goerli';
    case 42161:
      return 'Arbitrum';
    case 10:
      return 'Optimism';
    case 420:
      return 'Optimism Goerli';
    default:
      return 'development';
  }
};

export const getAlchemyNetworkByChainId = (chainId) => {
  switch (_.parseInt(chainId, 10)) {
    // Ethereum
    case 1:
      return 'eth-mainnet';
    case 5:
      return 'eth-goerli';

    // Polygon
    case 137:
      return 'polygon-mainnet';
    case 80001:
      return 'polygon-mumbai';

    // Arbitrum
    case 42161:
      return 'arb-mainnet';
    case 421613:
      return 'arb-goerli';

    // Optimism
    case 10:
      return 'opt-mainnet';
    case 420:
      return 'opt-goerli';

    // localhost
    default:
      return 'development';
  }
};
