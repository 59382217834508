// Frameworks
import React from 'react';
import window from 'global';
import * as _ from 'lodash';

// App components
import { useFiltersContext } from '../contexts/filters';
import { GLOBALS } from '../utils/globals.js';

// Material UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('Footer');
log.debug('initialized');


// Custom Styles
const getCustomStyles = typeId => (theme) => {
  const styles = {
    footer: {
      position: 'static',
      bottom: '0',
      left: '0',
      width: '100%',
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    button: {
      textTransform: 'none',
      color: theme.palette.grey[500],
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '0.75rem',
    },
    copyright: {
      color: theme.palette.grey[600],
      fontSize: '0.65rem',
    },
  };
  return styles[typeId];
};

const openLink = url => (event) => {
  event.preventDefault();
  window.open(url);
};

const externalLinks = _.map(_.keys(GLOBALS.EXTERNAL_LINKS), linkName => ({
  name: _.startCase(_.toLower(linkName)),
  url: GLOBALS.EXTERNAL_LINKS[linkName],
}));


const Footer = () => {
  const [ filtersState ] = useFiltersContext();

  const renderLinks = () => {
    if (GLOBALS.IS_PRE_LAUNCH) { return; }

    return _.map(externalLinks, (link, index) => {
      const url = _.toLower(link.name) === 'docs'
        ? link.url.HOME
        : link.url;

      return (
        <Box ml={-1} pr={1} key={index}>
          <Button variant="footerLink" size="small" onClick={openLink(url)} sx={getCustomStyles('button')}>
            {link.name}
          </Button>
        </Box>
      );
    });
  };

  const renderCopyright = () => {
    return (
      <Typography variant='body2' sx={getCustomStyles('copyright')}>
        {GLOBALS.COMPANY_NAME}<br/>All Rights Reserved &copy; {(new Date()).getFullYear()}
      </Typography>
    );
  };

  return (
    <footer>
      <Box sx={getCustomStyles('footer')}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {renderCopyright()}
          </Grid>
          <Grid item>
            {
              (filtersState.isFetchingNextListPage) && (
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ mt: 0.5 }}>
                  <CircularProgress color="primary" size={20} />
                  <Typography>Loading more...</Typography>
                </Stack>
              )
            }
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {renderLinks()}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
};

export { Footer };
