// Frameworks
import React, { Fragment } from 'react';
import _ from 'lodash';

// Material UI
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AudioFileIcon from '@mui/icons-material/AudioFile';

// App Hooks
import { useAuthContext } from '../../contexts/auth';
import { useMember } from '../../queries/useMember';
import { useMemberMusic } from '../../hooks/useMemberMusic';

// App Components
import { AudioDisplay } from '../AudioDisplay';
import { Loading } from '../Loading';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/LatestTracks');
log.debug('initialized');


const MemberLatestTracks = ({ memberId }) => {
  const [ authState ] = useAuthContext();
  const { isLoading: isMemberLoading, data: profileMember } = useMember(memberId);
  const { isLoading, data: music } = useMemberMusic({ memberId: profileMember?.memberId });

  const currentMemberId = _.get(authState, 'user.uid');
  const profileMemberId = profileMember?.memberId;
  const isProfileOwner = profileMemberId === currentMemberId;

  const _renderTrack = (musicInfo, index, isLast = false) => {
    return (
      <Box key={index} sx={{ mb: 3 }}>
        <AudioDisplay musicId={musicInfo.musicId} variant="track" />
      </Box>
    );
  };

  if (isLoading || isMemberLoading) {
    return (
      <Loading />
    );
  }

  if (_.isEmpty(music?.collection)) {
    if (isProfileOwner) {
      return <p>TODO: Let user know they can Upload Tracks &amp; Podcasts...</p>;
    }

    // Hide for Public Viewers if No Latest Tracks
    return null;
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">
        <AudioFileIcon sx={{ color: blue[500], mb: -0.5 }} /> Latest Tracks
      </Typography>

      <Box sx={{ width: '100%', mt: 1 }}>
        {_.map(music.collection, (musicInfo, index) => {
          const isLast = music.collection.length === (index + 1);
          return _renderTrack(musicInfo, index, isLast);
        })}
      </Box>
    </Box>
  );
};

export { MemberLatestTracks };
