// Frameworks
import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import axios from 'axios';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from './index';
import { ERC20KnownMetadata } from '../utils/erc20KnownMetadata';
import { getRpcUrl } from '../web3/getRpcUrl';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query Function
const queryFn = (chainId, tokenAddresses) => async () => {
  const endpoint = getRpcUrl(chainId, 'alchemy');
  const results = await Promise.all(_.map(tokenAddresses, (tokenAddress) => {
    const data = JSON.stringify({
      'jsonrpc': '2.0',
      'method': 'alchemy_getTokenMetadata',
      'params': [ `${tokenAddress}` ],
      'id': tokenAddress,
    });
    return axios({
      method: 'post',
      url: endpoint,
      headers: { 'Content-Type': 'application/json' },
      data,
    });
  }));
  return _transformTokenData(results, chainId);
};

// Custom Hook
const useTokenMetadata = (tokenAddresses, chainId) => {
  const enabled = !_.isEmpty(tokenAddresses);
  const tokensHash = ethers.utils.id(JSON.stringify(tokenAddresses));
  return useQuery(
    [ 'tokenMetadata', chainId, tokensHash ],
    queryFn(chainId, tokenAddresses),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformTokenData = (results, chainId) => {
  return _.map(results, (resultData) => {
    if (_.isEmpty(resultData.data.result.symbol)) {
      // Append known metadata
      resultData.data.result = {
        ...resultData.data.result,
        ...ERC20KnownMetadata[_.toUpper(resultData.data.id)] || {},
      };
    }
    return {
      tokenAddress: resultData.data.id,
      ...resultData.data.result,
      chainId,
    };
  });
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time for Aave Protocol Data
setQueryDefaults([ 'tokenMetadata' ], { staleTime: 24 * 60 * 1000 * 60 }); // 1 Day

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'tokenMetadata' ])


// Export Hook
export { useTokenMetadata };
