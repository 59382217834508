// Frameworks
import React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// App Components
// import { Helpers } from '../../utils/helpers';
// import { GLOBALS } from '../../utils/globals';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Upload/Review');
log.debug('initialized');


const UploadReview = ({
  formData,
}) => {
  return (
    <Box>
      <Typography>Review:</Typography>
      <Typography>&nbsp; - title = {formData.title}</Typography>
      <Typography>&nbsp; - artist = {formData.artist}</Typography>
      <Typography>&nbsp; - desc = {formData.desc}</Typography>
      <Typography>&nbsp; - genre = {formData.genre}</Typography>
      <Typography>&nbsp; - subgenre = {formData.subgenre}</Typography>
      <Typography>&nbsp; - audio = {formData.audio}</Typography>
      <Typography>&nbsp; - audioSize = {formData.audioSize}</Typography>
      <Typography>&nbsp; - audioMime = {formData.audioMime}</Typography>
      <Typography>&nbsp; - image = {formData.image}</Typography>
      <Typography>&nbsp; - imageSize = {formData.imageSize}</Typography>
      <Typography>&nbsp; - imageMime = {formData.imageMime}</Typography>
      <Typography>&nbsp; - imageDimensions = {JSON.stringify(formData.imageDimensions)}</Typography>
      <Typography>&nbsp; - hasBgColor = {formData.hasBgColor}</Typography>
      <Typography>&nbsp; - bgColor = {formData.bgColor}</Typography>
      <Typography>&nbsp; - attributes = {JSON.stringify(formData.attributes)}</Typography>
      <Typography>&nbsp; - salePrice = {formData.salePrice}</Typography>
      <Typography>&nbsp; - resaleRoyalty = {formData.resaleRoyalty}</Typography>
    </Box>
  );
};

export { UploadReview };
