// Frameworks
import React, { useState } from 'react';
import { navigate } from '@reach/router';
import * as _ from 'lodash';

// Material UI
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// App Hooks
import { useMember } from '../queries/useMember';

// App Components
import { Loading } from './Loading';
import { LoginButton } from './LoginButton';

// Common
import { useAuthContext } from '../contexts/auth';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('ProfileMenu');
log.debug('initialized');


const _menuItems = [
  { id: 'profile',  label: 'My Profile',  link: '/profile',  icon: AccountBoxIcon },
  { id: 'wallet',   label: 'My Wallet',   link: '/wallet',   icon: AccountBalanceWalletIcon },
  { id: 'settings', label: 'Settings', link: '/settings', icon: SettingsApplicationsIcon },
  { id: 'signout',  label: 'Sign Out', link: '#',         icon: ExitToAppIcon },
];


const ProfileMenu = ({ location, asList = false }) => {
  const [ authState, , signIn, signOut ] = useAuthContext();
  const { isLoading, data: profileMember } = useMember(_.get(authState, 'user.uid'));
  const [ anchorEl, setAnchorEl ] = useState(null);

  const _handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const _handleMenuItemClick = itemId => async (event) => {
    setAnchorEl(null);

    if (itemId === 'signout') {
      return signOut();
    }

    const menuItem = _.find(_menuItems, [ 'id', itemId ]) || _.first(_menuItems);
    let identifier = '';
    if (itemId === 'profile' && !_.isUndefined(profileMember.screenName)) {
      identifier = profileMember.screenName;
    }

    navigate(`${GLOBALS.APP_ROOT}${menuItem.link}${identifier}`);
  };

  const _handleMenuClose = () => {
    setAnchorEl(null);
  };

  const _renderUserMenu = () => {
    return (
      <>
        <Tooltip title="Account Menu">
          <IconButton
            color="primary"
            aria-controls="customized-menu"
            aria-haspopup="true"
            aria-label="profile menu"
            component="span"
            onClick={_handleMenuClick}
          >
            <Avatar
              alt={authState.user.displayName}
              title={authState.user.displayName}
              // src={profileImage}
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          open={Boolean(anchorEl)}
          onClose={_handleMenuClose}
          keepMounted
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {_.map(_menuItems, ({ id, label, icon: Icon }) => (
            <MenuItem
              key={id}
              onClick={_handleMenuItemClick(id)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const _renderUserList = () => {
    return (
      <>
        <List>
          <ListItem key="displayName">
            <ListItemAvatar>
              <Avatar
                alt={authState.user.displayName}
                title={authState.user.displayName}
                // src={profileImage}
              />
            </ListItemAvatar>
            <ListItemText primary={authState.user.displayName} disableTypography />
          </ListItem>
          <Divider />

          {_.map(_menuItems, ({ id, label, icon: Icon }) => (
            <ListItem
              button
              key={id}
              onClick={_handleMenuItemClick(id)}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label} disableTypography />
            </ListItem>
          ))}
        </List>
      </>
    );
  };


  // Render Loading State
  if (authState.isLoading || isLoading) {
    return <Loading />;
  }

  // Render Login Button
  if (!authState.isLoggedIn) {
    return <LoginButton loginFn={signIn} />;
  }

  // Render User Menu
  // const profileImage = `${authState.user.photoURL}?access_token=${authState.user.accessToken}`;
  // const profileId = _.get(authState.user, 'auth.currentUser.providerData.0.uid');
  // const profileImage = `https://graph.facebook.com/v13.0/${profileId}/picture?access_token=${authState.user.accessToken}`;

  if (asList) {
    return _renderUserList();
  }
  return _renderUserMenu();
};

export { ProfileMenu };
