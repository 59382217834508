// Frameworks
import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import axios from 'axios';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from './index';
import { getRpcUrl } from '../web3/getRpcUrl';
import { GLOBALS } from '../utils/globals';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query Function
const queryFn = (chainId, userAddress) => async () => {
  const data = JSON.stringify({
    'jsonrpc': '2.0',
    'method': 'alchemy_getTokenBalances',
    'params': [ `${userAddress}`, [ _.get(GLOBALS.REQUIRED_CURRENCY, `${chainId}.ADDRESS`, 'DEFAULT_TOKENS') ]],
    'id': chainId,
  });
  const response = await axios({
    method: 'post',
    url: getRpcUrl(chainId, 'alchemy'),
    headers: { 'Content-Type': 'application/json' },
    data,
  });
  return _transformTokenData(response.data.result, chainId);
};

// Custom Hook
const useUserTokenBalances = (userAddress, chainId) => {
  const enabled = !_.isEmpty(userAddress) && !!chainId;
  return useQuery(
    [ 'userTokenBalances', chainId, userAddress ],
    queryFn(chainId, userAddress),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformTokenData = (data, chainId) => {
  // Remove zero-balances
  const filtered = _.filter(data.tokenBalances, token => _.every([
    !_.isEmpty(token.tokenBalance),
    token.tokenBalance !== '0x',
    token.tokenBalance !== GLOBALS.ZERO_HEX,
  ], Boolean));

  // Convert balance from hex
  return _.map(filtered, (token) => {
    const balance = ethers.BigNumber.from(token.tokenBalance).toString();
    return { chainId, balance, ...token };
  });
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time for Aave Protocol Data
setQueryDefaults([ 'userTokenBalances' ], { staleTime: 3 * 1000 * 60 }); // 3 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'userTokenBalances' ])


// Export Hook
export { useUserTokenBalances };
