// Frameworks
import React from 'react';
import _ from 'lodash';

// Material UI
import { green } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PushPinIcon from '@mui/icons-material/PushPin';

// App Hooks
import { useMember } from '../../queries/useMember';
import { useMemberPinned } from '../../hooks/useMemberPinned';
import { useAuthContext } from '../../contexts/auth';

// App Components
import { Loading } from '../Loading';
import { AudioDisplay } from '../AudioDisplay';
import { CarouselRow } from '../CarouselRow';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/Pinned');
log.debug('initialized');


const MemberPinned = ({ memberId }) => {
  const [ authState ] = useAuthContext();
  const { isLoading: isMemberLoading, data: profileMember } = useMember(memberId);
  const { isLoading, data: music } = useMemberPinned({ memberId: profileMember?.memberId });

  const currentMemberId = _.get(authState, 'user.uid');
  const profileMemberId = profileMember?.memberId;
  const isProfileOwner = profileMemberId === currentMemberId;

  const _renderItem = (item) => {
    return <AudioDisplay musicId={item.musicId} variant="box" />;
  };

  if (isMemberLoading || isLoading) {
    return (
      <Loading />
    );
  }

  if (_.isEmpty(music?.collection)) {
    if (isProfileOwner) {
      return <p>TODO: Let user know they can Pin some tracks..</p>;
    }

    // Hide for Public Viewers if No Pinned Tracks
    return null;
  }

  return (
    <>
      <Stack direction="row" spacing={3}>
        <Typography variant="h6">
          <PushPinIcon sx={{ color: green[500], mb: -0.5 }} /> Pinned
        </Typography>
      </Stack>

      <CarouselRow items={music.collection} componentFn={_renderItem} />
    </>
  );
};

export { MemberPinned };
