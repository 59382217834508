// Frameworks
import React from 'react';

// Material UI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// App Contexts
// import { useWeb3Context } from '../contexts/web3';

// App Components
import SEO from '../components/seo';
import { SearchBar } from '../components/SearchBar';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] Home');
log.debug('initialized');


const Home = () => {
  // const [ web3 ] = useWeb3Context();

  const _handleSearch = (value) => {
    console.log(value);
  };

  return (
    <main style={{ width: '100%' }}>
      <SEO title={'Home'} />

      <Typography variant="h6" align="center">Carbon Opus (in {process.env.GATSBY_ENV})</Typography>

      <SearchBar onSearch={_handleSearch} />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item xs={12} md={6}>
          <Typography>Todo:</Typography>
          <hr />
          <Typography>Home Page:</Typography>
          <Typography> - big search bar</Typography>
          <Typography> - display featured songs &amp; playlists</Typography>
          <hr />
          <Typography>Search Results:</Typography>
          <Typography> - big search bar</Typography>
          <Typography> - search results</Typography>
          <Typography> - related suggestions</Typography>
          <hr />
          <Typography>Profile Page:</Typography>
          <Typography> - profile bio</Typography>
          <Typography> - pinned songs / playlists</Typography>
          <Typography> - my songs / playlists</Typography>
          <Typography> - all favorites</Typography>
          <Typography> - all playlists</Typography>
          <Typography> --- When Logged In</Typography>
          <Typography> ----- upload song</Typography>
          <Typography> ----- manage playlists</Typography>
          <hr />
          <Typography>Wallet:</Typography>
          <Typography> - connect wallet</Typography>
          <Typography> - claim earnings</Typography>
          <hr />
          <Typography>Song Page:</Typography>
          <Typography> - stream song</Typography>
          <Typography> - owner actions (fav, download, etc..)</Typography>
          <Typography> - Big Buy Button (add to cart)</Typography>
          <hr />
          <Typography>Jukebox Page:</Typography>
          <Typography> - Play a playlist</Typography>
          <Typography> - Play a queue</Typography>
          <Typography> - Play all by artist</Typography>
          <Typography> - Play random by genre/artist/year/etc..</Typography>
        </Grid>
      </Grid>
    </main>
  );
};

export default Home;
