import { grey, purple, amber } from '@mui/material/colors';

const BRANDING = {};

BRANDING.SIDEMENU_WIDTH = 274;
BRANDING.FOOTER_HEIGHT = 60;
BRANDING.THEME = {
  PRIMARY           : purple[500],
  SECONDARY         : amber[700],

  BACKGROUND: {
    default         : '#111',
    paper           : '#1F2235',
  },

  COMMON: {
    white             : { main: '#FFFFFF', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    ghostwhite        : { main: '#B9C3E8', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    ash               : { main: '#777E90', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    blueslate         : { main: '#5F6486', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    deepblue          : { main: '#1F2235', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    whaleskin         : { main: '#3C4A58', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    lime              : { main: '#10EB74', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    deepsea           : { main: '#22263C', light: '#FFF', dark: '#DDD', contrastText: '#333' },
    pink              : { main: '#EC407A', light: '#FFF', dark: '#DDD', contrastText: '#333' },
  },
};

export { BRANDING };
