// Frameworks
import React from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';

// App Components
import { GLOBALS } from '../../utils/globals';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/NoProfile');
log.debug('initialized');


const MemberNoProfile = ({ isInvalid }) => {
  return (
    <Paper variant="hero" watermark="true" color="secondary">
      <Typography variant="hero-watermark" component="p">{GLOBALS.COMPANY_NAME}</Typography>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mb: 3 }}
      >
        <SentimentDissatisfiedIcon sx={{ color: 'primary.main', fontSize: '3rem' }} />
        <Typography variant="h5" component="p">Ooops, we could not find that account!</Typography>

        <Typography variant="body1">
          {
            isInvalid ? 'The Member ID is likely invalid...' : 'The Member ID is missing...'
          }
        </Typography>
      </Stack>
    </Paper>
  );
};

export { MemberNoProfile };
