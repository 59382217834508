// Frameworks
import React from 'react';
import clsx from 'clsx';
import * as _ from 'lodash';

// Material UI
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import TwitterIcon from '@mui/icons-material/Twitter';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('LoginButton');
log.debug('initialized');

// Meta Logo
// import MetaSVG from '../../images/vendor/meta.svg';


// Custom Styles
const useCustomStyles = makeStyles(theme => ({
  loginButton: {
    // marginLeft: theme.spacing(3),
    // color: theme.colors.white,
    textTransform: 'none',

    '& svg': {
      width: 18,
      height: 18,
    },
  },
}));

function LoginButton({
  fullWidth = false,
  color = 'primary',
  variant = 'outlined',
  size = 'small',
  className = [],
  sx,
  loading = false,
  loadingPosition,
  loadingColor = 'inherit',
  label = 'Sign In',
  loginFn = _.noop,
}) {
  const customClasses = useCustomStyles();
  return (
    <LoadingButton
      fullWidth={fullWidth}
      aria-label="Login"
      onClick={loginFn}
      color={color}
      variant={variant}
      size={size}
      loading={loading}
      loadingPosition={loadingPosition}
      loadingIndicator={<CircularProgress color={loadingColor} size={16} />}
      className={clsx(className, customClasses.loginButton)}
      startIcon={<TwitterIcon sx={{ color: '#1DA1F2' }} />}
      sx={sx}
    >
      {label}
    </LoadingButton>
  );
}

export { LoginButton };
