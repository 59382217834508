// Frameworks
import React, { useState, useEffect, useRef } from 'react';
import window from 'global';
import _ from 'lodash';

// Material UI
import Grid from '@mui/material/Grid';

// App Hooks
import { useDebounce } from '../hooks';
import { useMember } from '../queries/useMember';
import { useAuthContext } from '../contexts/auth';

// App Components
import SEO from '../components/seo';
import { MemberBanner } from '../components/member/Banner';
import { MemberDetails } from '../components/member/Details';
import { MemberPinned } from '../components/member/Pinned';
import { MemberPromoted } from '../components/member/Promoted';
import { MemberLatestTracks } from '../components/member/LatestTracks';
import { MemberLatestAlbums } from '../components/member/LatestAlbums';
import { MemberPlaylists } from '../components/member/Playlists';
import { MemberGetStarted } from '../components/member/GetStarted';
import { MemberNoProfile } from '../components/member/NoProfile';
import { Loading } from '../components/Loading';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] Profile');
log.debug('initialized');


const Profile = ({ memberIdentifier }) => {
  const [ authState ] = useAuthContext();
  const currentMemberId = _.get(authState, 'user.uid');
  const { isLoading, isSuccess, data: profileMember } = useMember(memberIdentifier || currentMemberId);
  const profileMemberId = profileMember?.memberId;

  const [ scrollOffset, setScrollOffset ] = useState(0);
  const debouncedScrollPosition = useDebounce(scrollOffset, 25);
  const scrollContainerRef = useRef();
  const elementRef = useRef();

  useEffect(() => {
    const onScroll = () => {
      if (!scrollContainerRef.current) { return; }
      const containerTop = scrollContainerRef.current.offsetTop;
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
      const elementRect = elementRef.current.getBoundingClientRect();
      const marginBottom = 50;

      let moveTo = 0;
      if (window.pageYOffset > containerTop) {
        moveTo = window.pageYOffset - containerTop;
        if (moveTo + elementRect.height > containerRect.height - marginBottom) {
          moveTo = containerRect.height - elementRect.height - marginBottom;
        }
      }
      setScrollOffset(moveTo);
    };
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const _renderPageContent = () => {
    if (isLoading) {
      return (
        <Loading />
      );
    }
    if (_.isEmpty(profileMemberId)) {
      return (
        <MemberNoProfile isInvalid={isSuccess} />
      );
    }
    return (
      <>
        <MemberBanner memberId={profileMemberId} />
        <MemberDetails memberId={profileMemberId} />
        {
          !profileMember?.hasStarted
            ? (<MemberGetStarted memberId={profileMemberId} />)
            : (
              <>
                <MemberPinned memberId={profileMemberId} />
                <Grid
                  container
                  direction="row-reverse"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={3}
                  sx={{ position: 'relative' }}
                  ref={scrollContainerRef}
                >
                  <Grid item xs={12} md={4}>
                    <div
                      ref={elementRef}
                      style={{
                        position: 'relative',
                        top: debouncedScrollPosition,
                        right: 0,
                        transition: '0.8s',
                      }}
                    >
                      <MemberPromoted memberId={profileMemberId} />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <MemberLatestTracks memberId={profileMemberId} />
                  </Grid>
                </Grid>
                <MemberLatestAlbums memberId={profileMemberId} />
                <MemberPlaylists memberId={profileMemberId} />
              </>
            )
        }
      </>
    );
  };

  return (
    <>
      <SEO title={`${profileMember?.displayName} - ${GLOBALS.COMPANY_NAME}`} />

      {_renderPageContent()}
    </>
  );
};

export default Profile;
