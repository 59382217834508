// Frameworks
import React, { useState } from 'react';
import { validate } from 'react-email-validator';
import window from 'global';
import * as _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TwitterIcon from '@mui/icons-material/Twitter';
import EditIcon from '@mui/icons-material/Edit';

// App Components
import { useAuthContext } from '../contexts/auth';
import { notifyMe } from '../firebase/notifyMe';
import { LoginButton } from '../components/LoginButton';
import DiscordIcon from '../../images/discord.svg';

// App Common
import { WordRotator } from './WordRotator';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('PreLaunchLander');
log.debug('initialized');


const PreLaunchLander = () => {
  const [ authState, , signIn, signOut ] = useAuthContext();
  const [ isValidEmail, setIsValidEmail ] = useState(true);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ isSaved, setIsSaved ] = useState(false);
  const [ isEditMode, setIsEditMode ] = useState(false);
  const [ emailAddress, setEmailAddress ] = useState('');

  const hasTwitterEmail = !_.isEmpty(_.trim(authState?.user?.email));
  const notificationEmail = (hasTwitterEmail && !isEditMode) ? authState.user.email : emailAddress;

  const handleNotifyMe = async () => {
    const isValid = validate(notificationEmail);
    setIsValidEmail(isValid);

    if (isValid) {
      setIsSaving(() => true);
      await notifyMe({ email: notificationEmail });
      setIsSaving(() => false);
      setIsSaved(() => true);
    }
  };

  const handleEmailChange = (event) => {
    setIsValidEmail(validate(event.target.value));
    setEmailAddress(event.target.value);
  };

  const toggleEditMode = () => {
    setIsEditMode(() => {
      if (!isEditMode && hasTwitterEmail) {
        setEmailAddress(() => authState.user.email);
      }
      return !isEditMode;
    });
  };

  const handleSocial = socialType => () => {
    let url = 'https://twitter.com/CarbonOpus';
    if (socialType === 'discord') {
      url = 'https://discord.gg/cdyn3MNa5u';
    }
    window.open(url);
  };

  const renderLoginButton = () => {
    // Render Login Button
    if (!authState.isLoggedIn) {
      return (
        <Box sx={{ mt: 2 }}>
          <LoginButton
            size="large"
            label="Sign In with Twitter"
            loginFn={signIn}
            loading={authState.isLoading}
            loadingColor="primary"
            sx={{ backgroundColor: '#f5f5f5 !important' }}
          />
        </Box>
      );
    }
    return (
      <>
        {
          (!hasTwitterEmail || isEditMode) && (
            <TextField
              label="Email Address"
              variant="standard"
              value={emailAddress}
              onChange={handleEmailChange}
              helperText={isValidEmail ? ' ' : 'Invalid email address'}
              error={!isValidEmail}
              sx={{ mt: 2, width: { xs: 200, md: 300, lg: 400 } }}
            />
          )
        }
        <Stack direction="row" spacing={3} justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
          {
            !isSaved && (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleNotifyMe}
                loading={isSaving}
                disabled={(!hasTwitterEmail || isEditMode) && (_.isEmpty(emailAddress) || !isValidEmail)}
              >
                Notify Me
              </LoadingButton>
            )
          }
          <Button
            variant="outlined"
            color="primary"
            onClick={() => signOut()}
          >
            Sign Out
          </Button>
        </Stack>
        {
          isSaved && (
            <Typography variant="h6" color="success.main" component="div" sx={{ mt: 2 }}>
              You have successfully registered!
            </Typography>
          )
        }
        {
          (hasTwitterEmail || (isEditMode && !_.isEmpty(notificationEmail) && isValidEmail)) && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              We&apos;ll send a notification email to<br /><strong>{notificationEmail}</strong>
              {
                (hasTwitterEmail && !isEditMode) && (
                  <IconButton aria-label="edit" size="small" onClick={toggleEditMode}>
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                )
              }
            </Typography>
          )
        }
      </>
    );
  };

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
      borderRadius: 5,
      background: 'rgba(0,0,0,0.05)',
      mt: 3,
      pt: { xs: 10, lg: 20 },
      pb: { xs: 5, lg: 10 },
      px: { xs: 3, md: 5, lg: 10 },
    }}>
      <Typography variant="prelaunch-watermark" component="div">{GLOBALS.COMPANY_NAME}</Typography>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={3}
        sx={{
          mt: { xs: -7, lg: -12 },
          mb: { xs: 2, lg: 4 },
        }}
      >
        <Tooltip title="Visit us on Twitter">
          <IconButton aria-label="delete" color="primary" onClick={handleSocial('twitter')}>
            <TwitterIcon sx={{ fontSize: 60, color: '#1DA1F2' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Chat with us on Discord">
          <IconButton aria-label="delete" color="primary" onClick={handleSocial('discord')}>
            <DiscordIcon style={{ width: '60px' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        spacing={2}
      >
        <Typography variant="prelaunch-heading" component="div">
          <WordRotator wordList={[ 'Royalty-based', 'Decentralized', 'Web3', 'NFT-based', 'Free-to-Use' ]} variant="prelaunch-word" />
          <em>Audio Platform</em>
        </Typography>
        <Typography variant="prelaunch-heading" component="div">
          <strong>Rewarding Artists</strong> and <strong>Incentivizing Fans</strong>
        </Typography>
      </Stack>
      <Box sx={{ width: { xs: '100%', sm: '70%', md: '50%', lg: '40%' }, textAlign: 'center', pt: 5 }}>
        <Typography variant="h4" color="secondary" component="div" sx={{ fontStyle: 'italic' }}>
          Get a Launch Alert!
        </Typography>
        {renderLoginButton()}
      </Box>
    </Box>
  );
};

export { PreLaunchLander };
