// Frameworks
import React from 'react';
import _ from 'lodash';

// Material UI
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';

// App Contexts
import { useAudioContext } from '../contexts/audio';


const _getSliderStyles = (theme) => {
  return {
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&:before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${
          theme.palette.mode === 'dark'
            ? 'rgb(255 255 255 / 16%)'
            : 'rgb(0 0 0 / 16%)'
        }`,
      },
      '&.Mui-active': {
        width: 20,
        height: 20,
      },
    },
  };
};

const VolumeControl = ({ orientation = 'horizontal', size = 200, icons = true, spacing = 1 }) => {
  const theme = useTheme();
  const [ audio, audioDispatch ] = useAudioContext();

  const iconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

  const _handleVolumeChange = (event, value) => {
    audioDispatch({ type: 'UPDATE_VOLUME', payload: value });
  };

  const _preventHorizontalKeyboardNavigation = (event) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  };

  let keyDownHandler = _.noop;
  let direction = 'row';
  let boxStyles = { width: size };
  let stackStyles = { width: '100%', mb: 0, px: 1 };
  let sliderStyles = _getSliderStyles(theme);
  if (orientation === 'vertical') {
    direction = 'column-reverse';
    boxStyles = { height: size };
    stackStyles = { height: '100%', mb: 0.5, px: 0.5, py: 1 };
    sliderStyles = {
      ...sliderStyles,
      '& input[type="range"]': {
        WebkitAppearance: 'slider-vertical',
      },
    };
    keyDownHandler = _preventHorizontalKeyboardNavigation;
  }

  return (
    <Box sx={boxStyles}>
      <Stack spacing={spacing} direction={direction} sx={stackStyles} alignItems="center">
        {icons && <VolumeDownRounded htmlColor={iconColor} />}
        <Slider
          aria-label="Volume"
          orientation={orientation}
          defaultValue={audio?.volume}
          value={audio?.volume}
          min={0}
          step={1}
          max={100}
          onChange={_handleVolumeChange}
          sx={sliderStyles}
          onKeyDown={keyDownHandler}
        />
        {icons && <VolumeUpRounded htmlColor={iconColor} />}
      </Stack>
    </Box>
  );
};

export { VolumeControl };
