// Frameworks
import React, { useState } from 'react';
// import _ from 'lodash';

// Material UI
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

// App Components
import { useAudioContext } from '../contexts/audio';
import { VolumeControl } from './VolumeControl';
import { GLOBALS } from '../utils/globals';


const VolumeButton = () => {
  const [ audio ] = useAudioContext();
  const [ anchorEl, setAnchorEl ] = useState(null);
  // const [ lastVolume, setLastVolume ] = useState(null);
  const open = Boolean(anchorEl);

  // const handleMute = (event) => {
  //   if (audio?.volume > 0) {
  //     setLastVolume(audio?.volume);
  //     audioDispatch({ type: 'UPDATE_VOLUME', payload: 0 });
  //   } else {
  //     audioDispatch({ type: 'UPDATE_VOLUME', payload: lastVolume });
  //   }
  // };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={handleOpen}
      >
        {
          audio?.volume === 0
            ? <VolumeOffIcon />
            : audio?.volume > 50
              ? <VolumeUpRounded />
              : <VolumeDownRounded />
        }
      </IconButton>
      <Popover
        id="volume-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ zIndex: GLOBALS.BASE_Z_INDEX + 2 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <VolumeControl orientation="vertical" />
      </Popover>
    </>
  );
};

export { VolumeButton };
