// Frameworks
import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('CarouselRow');
log.debug('initialized');


const _swiperBreakpoints = {
  600: {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  900: {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 5,
    spaceBetween: 10,
  },
};

const CarouselRow = ({ items, componentFn = _.noop }) => {
  const swiperRef = useRef();
  const [ atStart, setAtStart ] = useState(true);
  const [ atEnd, setAtEnd ] = useState(false);

  useEffect(() => {
    const _handleSlide = (swiper) => {
      setAtStart(swiper.isBeginning);
      setAtEnd(swiper.isEnd);
    };
    if (swiperRef?.current) {
      swiperRef.current.on('slideChange', _handleSlide);
    }
    return () => {
      if (swiperRef?.current) {
        swiperRef.current.off('slideChange', _handleSlide);
      }
    };
  }, [ swiperRef ]);

  const _renderSlideItem = (item, index) => {
    return (
      <SwiperSlide key={index}>
        <Box sx={{ paddingLeft: '3px', pb: 3 }}>
          {componentFn(item)}
        </Box>
      </SwiperSlide>
    );
  };

  return (
    <Box sx={{ position: 'relative', px: 5, mb: 4 }}>
      <Swiper
        onBeforeInit={(swiper) => { swiperRef.current = swiper; }}
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={_swiperBreakpoints}
      >
        {_.map(items, _renderSlideItem)}
      </Swiper>
      <IconButton
        aria-label="prev"
        disabled={atStart}
        sx={{ position: 'absolute', top: '30%', left: 0 }}
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <ArrowBackIosNewOutlinedIcon />
      </IconButton>
      <IconButton
        aria-label="next"
        disabled={atEnd}
        sx={{ position: 'absolute', top: '30%', right: 0 }}
        onClick={() => swiperRef.current?.slideNext()}
      >
        <ArrowForwardIosOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export { CarouselRow };
