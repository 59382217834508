// Frameworks
import React, { useState, useEffect } from 'react';
import equal from 'fast-deep-equal';
import _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

// App Hooks
import { useAuthContext } from '../../contexts/auth';
import { useMember } from '../../queries/useMember';

// App Components
import { Loading } from '../Loading';
import { AttrListContainer } from '../attributeList/AttrListContainer';
import { musicGenres } from '../../utils/musicGenres';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Upload/Details');
log.debug('initialized');


const UploadDetails = ({
  onChange = _.noop,
  displayErrors = false,
  formData: existingFormData,
}) => {
  const [ authState ] = useAuthContext();
  const { isLoading, data: currentMember } = useMember(authState?.user?.uid);
  const [ audioState, setAudioState ] = useState(existingFormData);

  useEffect(() => {
    if (!equal(existingFormData, audioState)) {
      const isValid = !!(audioState?.titleValid && audioState?.genreValid);
      onChange({ isValid, data: audioState });
    }
  }, [ audioState, existingFormData, onChange ]);

  const _updateMusicTitle = (event) => {
    const value = event.target.value;
    setAudioState(oldState => ({
      ...oldState,
      title: value,
      titleValid: !_.isEmpty(value),
    }));
  };

  const _updateMusicArtist = (event) => {
    const value = event.target.value;
    setAudioState(oldState => ({
      ...oldState,
      artist: value,
    }));
  };

  const _handleArtistLock = () => {
    setAudioState(oldState => ({
      ...oldState,
      hasCustomArtist: !oldState.hasCustomArtist,
    }));
  };

  const _handleMouseDownArtistLock = (event) => {
    event.preventDefault();
  };

  const _updateMusicGenre = (event) => {
    const value = event.target.value;
    setAudioState(oldState => ({
      ...oldState,
      genre: value,
      subgenre: '',
      genreValid: !_.isEmpty(value),
    }));
  };

  const _updateMusicSubGenre = (event) => {
    const value = event.target.value;
    setAudioState(oldState => ({
      ...oldState,
      subgenre: value,
    }));
  };

  const _updateMusicDesc = (event) => {
    const value = event.target.value;
    setAudioState(oldState => ({
      ...oldState,
      desc: value,
    }));
  };

  const _onAttributesUpdate = (attributes) => {
    setAudioState(oldState => ({
      ...oldState,
      attributes: [ ...attributes ],
    }));
  };

  const _getMusicGenres = (root = '') => {
    if (_.isEmpty(root)) {
      return _.compact(_.keys(musicGenres));
    }
    return _.compact(_.get(musicGenres, root, []));
  };


  if (isLoading) {
    return (
      <Loading />
    );
  }
  return (
    <Grid
      container
      spacing={3}
    >
      <Grid item xs={12} md={6}>
        <TextField
          id="fileTypeName"
          placeholder="Song Title"
          variant="standard"
          onChange={_updateMusicTitle}
          value={audioState.title}
          fullWidth
          required
          error={displayErrors && !audioState.titleValid}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: '100%' }} variant="standard">
          <Input
            id="standard-adornment-password"
            type="text"
            placeholder={currentMember?.displayName}
            value={!audioState.hasCustomArtist ? '' : audioState.artist}
            onChange={_updateMusicArtist}
            disabled={!audioState.hasCustomArtist}
            endAdornment={
              <InputAdornment position="end">
                <Tooltip title={audioState.hasCustomArtist ? 'Lock Artist' : 'Unlock Artist'}>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={_handleArtistLock}
                    onMouseDown={_handleMouseDownArtistLock}
                  >
                    {audioState.hasCustomArtist ? <LockIcon /> : <LockOpenIcon />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="musicGenre">Genre</InputLabel>
            <Select
              labelId="musicGenre"
              id="demo-simple-select"
              value={audioState.genre}
              label="Genre"
              required
              error={displayErrors && !audioState.genreValid}
              onChange={_updateMusicGenre}
            >
              {_.map(_getMusicGenres(), (g, i) => (
                <MenuItem key={i} value={g}>{g}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="musicGenre">Sub-Genre</InputLabel>
            <Select
              labelId="musicSubGenre"
              id="demo-simple-select"
              value={audioState.subgenre}
              label="Sub-Genre"
              onChange={_updateMusicSubGenre}
              disabled={_.isEmpty(audioState.genre)}
            >
              <MenuItem key="none" value="">None</MenuItem>
              {_.map(_getMusicGenres(audioState.genre), (g, i) => (
                <MenuItem key={i} value={g}>{g}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="fileTypeDesc"
          placeholder="Optional short description..."
          variant="standard"
          onChange={_updateMusicDesc}
          value={audioState.desc}
          multiline
          rows="4"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Box mb={1}>
          <Typography variant="overline">NFT Metadata Attributes</Typography>
        </Box>
        <AttrListContainer
          initialAttributes={audioState.attributes}
          onUpdate={_onAttributesUpdate}
        />
      </Grid>
    </Grid>
  );
};

export { UploadDetails };
