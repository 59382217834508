// Frameworks
import { useQuery } from 'react-query';
import axios from 'axios';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from './index';
import { GLOBALS } from '../utils/globals';


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query
const queryFn = musicId => async () => {
  return _transformResponse(await axios({
    url: `/music/${musicId}`,
    method: 'get',
    baseURL: GLOBALS.CARBONOPUS_API_URL,
  }));
};

// Custom Hook
const useMusic = ({ musicId }) => {
  const enabled = !_.isEmpty(musicId);
  return useQuery(
    [ 'music', musicId ],
    queryFn(musicId),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformResponse = (response) => {
  return { ..._.get(response, 'data.data') };
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time
setQueryDefaults([ 'music' ], { staleTime: 60 * 1000 * 60 }); // 60 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'music' ])


// Export Hook
export { useMusic };
