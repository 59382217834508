// Frameworks
import * as _ from 'lodash';

// App Components
import { getChainNameById, getAlchemyNetworkByChainId } from './getChainNameById';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('getRpcUrl');

// possible prefixes are "eth", "polygon", "arb", "opt"
export const getRpcUrl = (chainId = GLOBALS.DEFAULT_CHAIN_ID, preferredNode = 'alchemy') => {
  const chain = _.parseInt(chainId, 10);
  const chainName = getChainNameById(chainId);
  const alchemyNetworkName = getAlchemyNetworkByChainId(chainId);

  if (_.includes(GLOBALS.LAYER_2_NETWORKS, chain)) {
    preferredNode = 'alchemy';
  }

  let apiKey, url;
  switch (_.toLower(preferredNode)) {
    case 'alchemy':
      apiKey = GLOBALS.ALCHEMY_APIKEY[chainId];
      url = `https://${alchemyNetworkName}.g.alchemy.com/v2/${apiKey}`;
      break;
    default:
      apiKey = GLOBALS.INFURA_APIKEY;
      url = `https://${chainName}.infura.io/v3/${apiKey}`;
      break;
  }

  if (_.isUndefined(apiKey)) {
    log.warn(`Developer Error: "apikey" is undefined for chain "${chain}", check your .env or .env.development file!`);
  }

  return url;
};
