// Frameworks
import React from 'react';
import _ from 'lodash';

// Material UI
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// App Hooks
import { useAuthContext } from '../../contexts/auth';
import { useMember } from '../../queries/useMember';
import { useMemberPlaylists } from '../../hooks/useMemberPlaylists';

// App Components
import { Loading } from '../Loading';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/Playlists');
log.debug('initialized');


const MemberPlaylists = ({ memberId }) => {
  const [ authState ] = useAuthContext();
  const { isLoading: isMemberLoading, data: profileMember } = useMember(memberId);
  const { isLoading, data: music } = useMemberPlaylists({ memberId: profileMember?.memberId });

  const currentMemberId = _.get(authState, 'user.uid');
  const profileMemberId = profileMember?.memberId;
  const isProfileOwner = profileMemberId === currentMemberId;

  if (isMemberLoading || isLoading) {
    return (
      <Loading />
    );
  }

  if (_.isEmpty(music?.collection)) {
    if (isProfileOwner) {
      return <p>TODO: Let user know they can Create Playlists..</p>;
    }

    // Hide for Public Viewers if No Playlists
    return null;
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h6">
        <FormatListBulletedIcon sx={{ color: blue[500], mb: -0.5 }} /> My Playlists
      </Typography>
      <p>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
        ...<br/>
      </p>
    </Box>
  );
};

export { MemberPlaylists };
