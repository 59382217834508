// Frameworks
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import {
  TwitterAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';
import * as _ from 'lodash';

// App Components
import { getFirebase } from '../firebase';
import { invalidateQueries } from '../queries';

const twitterProvider = new TwitterAuthProvider();

const initialState = {
  // Firebase Authentication
  isLoading: false,
  isLoggedIn: false,
  user: null,
};

export const AuthContext = createContext(initialState);

export function useAuthContext() {
  return useContext(AuthContext);
}

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_AUTH_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'UPDATE_AUTH_USER':
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: !_.isEmpty(action.payload.user),
      };
    default:
      return state;
  }
};

export default function Provider({ children }) {
  const [ state, dispatch ] = useReducer(AuthReducer, initialState);
  const { auth } = getFirebase();

  const signInWithTwitter = () => {
    dispatch({ type: 'UPDATE_AUTH_LOADING', payload: true });
    return signInWithRedirect(auth, twitterProvider);
  };

  return (
    <AuthContext.Provider value={[ state, dispatch, signInWithTwitter, () => auth.signOut() ]}>
      {children}
    </AuthContext.Provider>
  );
}

export function Updater() {
  const { auth } = getFirebase();
  const [ , dispatch ] = useAuthContext();

  useEffect(() => {
    dispatch({ type: 'UPDATE_AUTH_LOADING', payload: true });
    getRedirectResult(auth).then((result) => {
      if (result === null) {
        dispatch({ type: 'UPDATE_AUTH_LOADING', payload: false });
      }
      invalidateQueries([ 'useMember' ]);
    });

    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch({ type: 'UPDATE_AUTH_USER', payload: { user } });
      dispatch({ type: 'UPDATE_AUTH_LOADING', payload: false });
    });

    return () => {
      unsubscribe();
    };
  }, [ auth, dispatch ]);

  return null;
}
