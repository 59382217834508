// Frameworks
import React from 'react';
// import _ from 'lodash';

// Material UI
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// App Hooks
// import { useMember } from '../../queries/useMember';
// import { useAuthContext } from '../../contexts/auth';

// App Components
// import { WallPaper } from '../components/WallPaper';
// import { Loading } from '../Loading';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/Banner');
log.debug('initialized');


const MemberBanner = ({ memberId }) => {
  return (
    <Paper variant="banner" sx={{ position: 'relative', zIndex: -1 }}>
      <Typography variant="h6" align="center">
        [...My Tagline here...]
      </Typography>
    </Paper>
  );
};

export { MemberBanner };
