// Frameworks
import { useQuery } from 'react-query';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from '../queries';
import { getMemberAlbums } from '../firebase/getMemberAlbums';


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query
const queryFn = memberId => async () => {
  return _transformResponse(await getMemberAlbums({ memberId }), { memberId });
};

// Custom Hook
const useMemberAlbums = ({ memberId }) => {
  const enabled = !_.isEmpty(memberId);
  return useQuery(
    [ 'memberAlbums', memberId ],
    queryFn(memberId),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformResponse = (response, extraData) => {
  return { ..._.get(response, 'data.data'), ...extraData };
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time
setQueryDefaults([ 'memberAlbums' ], { staleTime: 60 * 1000 * 60 }); // 60 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'memberAlbums' ])


// Export Hook
export { useMemberAlbums };
