
import * as _ from 'lodash';
import { GLOBALS } from './globals';

export const ERC20KnownMetadata = {};

const _OPUS = {
  name      : 'CarbonOpus OPUS',
  logo      : '/logo-transparent.png',
  symbol    : 'OPUS',
  decimals  : '18',
};

// OPUS on Ropsten, Goerli, Mumbai, Polygon
ERC20KnownMetadata[_.toUpper(GLOBALS.CONTRACT_ADDRESS.OPUS['3'])] = _OPUS;
ERC20KnownMetadata[_.toUpper(GLOBALS.CONTRACT_ADDRESS.OPUS['5'])] = _OPUS;
// ERC20KnownMetadata[_.toUpper(GLOBALS.CONTRACT_ADDRESS.OPUS['137'])] = _OPUS;
ERC20KnownMetadata[_.toUpper(GLOBALS.CONTRACT_ADDRESS.OPUS['80001'])] = _OPUS;


// USDC on Ropsten & Goerli
ERC20KnownMetadata[_.toUpper('0x07865c6E87B9F70255377e024ace6630C1Eaa37F')] = {
  name      : 'Circle USD\\C',
  logo      : 'https://cryptologos.cc/logos/usd-coin-usdc-logo.svg?v=022',
  symbol    : 'USDC',
  decimals  : '6',
};

export default ERC20KnownMetadata;
