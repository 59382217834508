// Frameworks
import React from 'react';
import { navigate } from 'gatsby';
import _ from 'lodash';

// Material UI
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

// App Hooks
import { useAuthContext } from '../../contexts/auth';
import { useMember } from '../../queries/useMember';

// App Components
import { Loading } from '../Loading';
import { GLOBALS } from '../../utils/globals';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/GetStarted');
log.debug('initialized');


const MemberGetStarted = ({ memberId }) => {
  const [ authState ] = useAuthContext();
  const { isLoading, data: profileMember } = useMember(memberId);

  const currentMemberId = _.get(authState, 'user.uid');
  const profileMemberId = profileMember?.memberId;
  const isProfileOwner = profileMemberId === currentMemberId;

  const _goHome = () => {
    navigate(GLOBALS.APP_ROOT);
  };

  const _redirectToUpload = () => {
    navigate(`${GLOBALS.APP_ROOT}/upload`);
  };


  if (isLoading) {
    return (
      <Loading />
    );
  }

  // TODO: If No: Tracks, Albums, Pinned, Promoted, Playlists
  if (isProfileOwner) {
    return (
      <Paper variant="hero" watermark="true" color="secondary">
        <Typography variant="hero-watermark" component="p">{GLOBALS.COMPANY_NAME}</Typography>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ mb: 3 }}
        >
          <AutoAwesomeIcon sx={{ color: 'primary.main', fontSize: '3rem' }} />
          <Typography variant="h5" component="p">Let&apos;s Get Started!</Typography>

          <Typography variant="body1">
            Start by Uploading your Music and/or Podcasts!
          </Typography>
          <Button size="large" variant="contained" onClick={_redirectToUpload}>Upload New Music</Button>
        </Stack>
        <Divider />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ mt: 3 }}
        >
          <Typography variant="body1">
            Or just browse around and add Music to your Playlists!
          </Typography>
          <Button size="large" variant="outlined" color="secondary" onClick={_goHome}>Browse Around</Button>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            You can <strong>Promote</strong> any Tracks or Albums you add to your Playlists and <strong>Earn Rewards</strong> for referring other listeners!
          </Typography>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper variant="hero" watermark="true" color="secondary">
      <Typography variant="hero-watermark" component="p">{GLOBALS.COMPANY_NAME}</Typography>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mb: 3 }}
      >
        <HourglassEmptyIcon sx={{ color: 'primary.main', fontSize: '3rem' }} />
        <Typography variant="h5" component="p">This account is still getting started!</Typography>

        <Typography variant="body1">
          Check back later to see if they have added any Music!
        </Typography>
      </Stack>
    </Paper>
  );
};

export { MemberGetStarted };
