// Frameworks
import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import axios from 'axios';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from './index';
import { getRpcUrl } from '../web3/getRpcUrl';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query Function
const queryFn = (chainId, userAddress) => async () => {
  const data = JSON.stringify({
    'jsonrpc': '2.0',
    'method': 'eth_getBalance',
    'params': [ `${userAddress}`, 'latest' ],
    'id': chainId,
  });
  const response = await axios({
    method: 'post',
    url: getRpcUrl(chainId, 'alchemy'),
    headers: { 'Content-Type': 'application/json' },
    data,
  });
  return _transformTokenData(response.data.result, chainId);
};

// Custom Hook
const useUserBalance = (userAddress, chainId) => {
  const enabled = !_.isEmpty(userAddress) && !!chainId;
  return useQuery(
    [ 'useUserBalance', chainId, userAddress ],
    queryFn(chainId, userAddress),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformTokenData = (data, chainId) => ({
  chainId,
  ethBalance: _.parseInt(data, 16),
  friendlyEthBalance: ethers.utils.formatUnits(ethers.BigNumber.from(data), 18),
});


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time for Aave Protocol Data
setQueryDefaults([ 'useUserBalance' ], { staleTime: 4 * 1000 * 60 }); // 4 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'useUserBalance' ])


// Export Hook
export { useUserBalance };
