// Frameworks
import React from 'react';
import { navigate, Link } from 'gatsby';
import _ from 'lodash';

// Material UI
import { amber } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';

import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// App Hooks
import { useMusic } from '../queries/useMusic';
import { useAudioContext } from '../contexts/audio';

// App Components
import { Loading } from './Loading';
import { GLOBALS } from '../utils/globals';
import { BRANDING } from '../layout/styles/branding';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('AudioDisplay');
log.debug('initialized');

const _linkStyles = {
  color: BRANDING.THEME.SECONDARY,
  textDecoration: 'none',
};


const AudioDisplay = ({ musicId, variant = 'card' }) => {
  const [ audio, audioDispatch ] = useAudioContext();
  const { isLoading, data: music } = useMusic({ musicId });

  const isSelected = audio?.current?.musicId === musicId;
  const isPlaying = isSelected && audio?.isPlaying;
  const coverImgSrc = _.get(music, 'metadata.thumbnail.http', _.get(music, 'metadata.properties.coverImage.http', ''));
  const fullTitle = `${music?.metadata?.title} by ${music?.metadata?.artist}`;

  const _redirectToMusic = musicId => () => {
    navigate(`${GLOBALS.APP_ROOT}/music/${musicId}`);
  };

  const _toggleMusic = () => {
    if (!isSelected) {
      audioDispatch({ type: 'CURRENT_AUDIO', payload: music });
      setTimeout(() => audioDispatch({ type: 'UPDATE_PLAYING', payload: true }), 100);
    } else {
      audioDispatch({ type: 'UPDATE_PLAYING', payload: !isPlaying });
    }
  };

  //
  // Variant = item
  //

  const _renderAsItem = () => {
    return (
      <ListItemButton alignItems="flex-start" onClick={_redirectToMusic(musicId)}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center">
            <ListItemAvatar sx={{ mt: 0.5 }}>
              <Avatar alt={fullTitle} src={coverImgSrc} variant="square" sx={{ width: 42, height: 42 }}  />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="tight" component="div" ellipsis="true" sx={{ width: { xs: 400, md: 150, lg: 230 } }}>
                  {music.metadata.title}
                </Typography>
              }
              secondary={
                <Typography variant="tiny" component="div" ellipsis="true" sx={{ width: { xs: 400, md: 150, lg: 230 } }}>
                  by {music.metadata.artist}
                </Typography>
              }
            />
          </Stack>
          <ArrowForwardIosIcon sx={{ color: amber[700] }} />
        </Stack>
      </ListItemButton>
    );
  };

  //
  // Variant = track
  //

  const _renderAsTrack = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Grid item>
          <Avatar
            alt={fullTitle}
            src={coverImgSrc}
            variant="track"
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            color="primary"
            component="div"
            ellipsis="true"
            sx={{ mb: 0, maxWidth: { xs: 300, sm: 380, lg: 540, xl: 620 } }}
          >
            {music.metadata.title}
          </Typography>
          <Typography
            variant="tiny"
            component="div"
            ellipsis="true"
            sx={{ mb: 0, maxWidth: { xs: 300, sm: 380, lg: 540, xl: 620 } }}
          >
            by {music.metadata.artist}
          </Typography>
          <Stack direction="row" spacing={0.5} sx={{ mb: 0.2 }}>
            <Typography variant="tiny" component="p">feat.</Typography>
            <Typography variant="tiny" component="p">
              <Link to={`${GLOBALS.APP_ROOT}/profile/someone_screenname`} style={_linkStyles}>Someone</Link>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={0.5} sx={{ mb: 0.2 }}>
            <Typography variant="tiny" component="p">Album:</Typography>
            <Typography variant="tiny" component="p">
              <Link to="#" style={_linkStyles}>19 &amp; Dangerous</Link>
            </Typography>
          </Stack>
          <Typography variant="tiny" component="p">Release Date: September 28, 2022</Typography>
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <Chip label={music.metadata.genre} variant="outlined" size="small" />
            {
              !_.isEmpty(music.metadata.subgenre) && (
                <Chip label={music.metadata.subgenre} variant="outlined" size="small" />
              )
            }
          </Stack>
        </Grid>
      </Grid>
    );
  };

  //
  // Variant = box
  //

  const _renderAsBox = () => {
    const width = { xs: 230, md: 180, lg: 200 };
    return (
      <Card sx={{ minWidth: width, maxWidth: width }}>
        <CardActionArea onClick={_redirectToMusic(musicId)}>
          <CardMedia
            image={coverImgSrc}
            sx={{ m: 1, height: 170, borderRadius: 2 }}
          />
          <CardContent sx={{ px: 1, py: 0 }}>
            <Typography
              gutterBottom
              color="text.secondary"
              variant="subtitle2"
              component="div"
              ellipsis="true"
              sx={{ textAlign: 'center', lineHeight: 1, mb: 0 }}
            >
              {music.metadata.artist}
            </Typography>
            <Typography
              gutterBottom
              color="primary"
              variant="h6"
              ellipsis="true"
              component="div"
              sx={{ textAlign: 'center' }}
            >
              {music.metadata.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  //
  // Variant = card
  //

  const _renderAsCard = () => {
    return (
      <Card
        variant="music"
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <CardMedia
          component="img"
          width="200"
          height="200"
          alt={fullTitle}
          src={coverImgSrc}
          sx={{
            borderRadius: 1.5,
            width: 'clamp(200px, (304px - 100%) * 999 , 100%)',
          }}
        />
        <Box sx={{ width: '100%', alignSelf: 'center', px: { xs: 0, sm: 2 } }}>
          <Typography
            variant="body1"
            color="text.primary"
            fontWeight={600}
            sx={{
              textAlign: { xs: 'center', sm: 'start' },
              mt: { xs: 1.5, sm: 0 },
            }}
          >
            {fullTitle}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="text.secondary"
            fontWeight={500}
            sx={{ textAlign: { xm: 'center', sm: 'start' } }}
          >
            {music.metadata.description}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ pt: 2 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                aria-label={!isPlaying ? 'play' : 'pause'}
                startIcon={!isPlaying ? <PlayArrowRounded /> : <PauseRounded />}
                onClick={_toggleMusic}
              >
                {!isPlaying ? 'Play' : 'Pause'}
              </Button>
            </Grid>

            <Grid item>
              <IconButton aria-label="add to cart">
                <AddShoppingCartIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Card>
    );
  };

  //
  // Render
  //

  if (isLoading) {
    return (
      <Loading />
    );
  }

  if (variant === 'item') {
    return _renderAsItem();
  }
  if (variant === 'track') {
    return _renderAsTrack();
  }
  if (variant === 'box') {
    return _renderAsBox();
  }
  return _renderAsCard();
};

export { AudioDisplay };
