// Frameworks
import React, { createContext, useContext, useReducer } from 'react';
import * as _ from 'lodash';

// App Components
// import { useLocation } from '../hooks/useLocation';
import { GLOBALS } from '../utils/globals';

const initialState = {
  currentSearch: { search: '', isLoading: false, isValid: false },
  includeTestNetworks: !GLOBALS.IS_PRD,
  chainId: GLOBALS.DEFAULT_CHAIN_ID,
  isFetchingNextListPage: false,
};
export const FiltersContext = createContext(initialState);

export function useFiltersContext() {
  return useContext(FiltersContext);
}


const FiltersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CHAIN_ID':
      return {
        ...state,
        chainId: _.parseInt(action.payload.chainId),
      };
    case 'UPDATE_SEARCH':
      return {
        ...state,
        currentSearch: { ...action.payload },
      };
    case 'SET_INCLUDE_TESTNETS':
      return {
        ...state,
        includeTestNetworks: action.payload,
      };
    case 'SET_GRID_LOADING_STATE':
      return {
        ...state,
        isFetchingNextListPage: action.payload,
      };
    default:
      return state;
  }
};

export default function Provider({ children }) {
  const [ state, dispatch ] = useReducer(FiltersReducer, initialState);
  return (
    <FiltersContext.Provider value={[ state, dispatch ]}>
      {children}
    </FiltersContext.Provider>
  );
}

export function Updater() {
  // const [ , dispatch ] = useFiltersContext();
  // const { locationParams } = useLocation();
  // const chainRef = useRef();

  // Load Chain ID from URL
  // useEffect(() => {
  //   const { chainId } = locationParams;
  //   if (_.includes(GLOBALS.SUPPORTED_NETWORK_IDS, _.parseInt(chainId)) && chainRef.current !== chainId) {
  //     dispatch({ type: 'SET_CHAIN_ID', payload: { chainId } });
  //     chainRef.current = chainId;
  //   }
  // }, [ locationParams, dispatch ]);

  return null;
}
