// Frameworks
import { v4 as uuidv4 } from 'uuid';
import { ethers } from 'ethers';
import window from 'global';
import * as _ from 'lodash';

// App Components
import { GLOBALS } from './globals';

const Helpers = {};

Helpers.now = () => {
  return (new Date()).getTime();
};

Helpers.isDate = (date) => {
  return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date));
};

Helpers.uuid = () => {
  return uuidv4();
};

Helpers.sleep = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

Helpers.hideInitialLoading = (delay = 1000) => {
  setTimeout(() => {
    const style = document.createElement('style');
    style.innerHTML = '#___gatsby::before,#___gatsby::after {display: none !important;}';
    const ref = document.querySelector('link');
    ref.parentNode.insertBefore(style, ref);
  }, delay);
};

Helpers.getQueryString = (location, key) => {
  const urlParams = new URLSearchParams(location?.search?.replace('?', ''));
  return urlParams.get(key);
};

// Update URL with new Query String values without changing route
Helpers.updateQueryString = (key, value, routeTitle = 'Home') => {
  const oldParams = new URLSearchParams(location.search.replace('?', ''));
  oldParams.set(key, value);
  const newParams = oldParams.toString();
  window.history.replaceState(null, routeTitle, `${location.origin}${location.pathname}?${newParams}`);
};

Helpers.isSameAddress = (addressA, addressB) => {
  return _.toLower(addressA) === _.toLower(addressB);
};

Helpers.isBurnAddress = (address) => {
  const burnAddresses = [
    GLOBALS.ADDRESS_ZERO,
    GLOBALS.ADDRESS_DEAD_CODE,
    '0x000000000000000000000000000000000000dEaD',
  ];
  return -1 !== _.findIndex(burnAddresses, burn => (_.toLower(burn) === _.toLower(address)));
};

Helpers.bigIntToStr = (bigIntValue) => {
  return ethers.BigNumber.from(bigIntValue).toString();
};

Helpers.getShortText = ({ text, length = 4, separator = '...', prefix = '' }) => {
  if (text.length <= length * 2) {
    return text;
  }
  return _.join(
    [
      ..._.slice(text, 0, length + prefix.length),
      separator,
      ..._.slice(text, -length),
    ],
    '',
  );
};

Helpers.getFriendlyAddress = ({ address, digits = 6, separator = ' ... ', prefix = '0x' }) => {
  return Helpers.getShortText({ text: address, length: digits, separator, prefix });
};

Helpers.formatCurrency = (amount) => {
  const dollarUSLocale = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return dollarUSLocale.format(amount);
};

Helpers.formatTimestamp = (timestamp, locale = 'en-US') => {
  return (new Date(timestamp * 1000)).toLocaleString(locale, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

Helpers.getNotEmpty = (obj, path, def) => {
  let val = _.get(obj, path, def);
  if (_.isEmpty(val)) { val = def; }
  return val;
};

Helpers.toHex = (num) => {
  return '0x' + num.toString(16);
};

Helpers.getContractData = ({ name }) => {
  const contractData = GLOBALS.CONTRACT_DATA[_.toUpper(name)];
  if (_.isEmpty(contractData)) {
    console.error(`[DEV-ERROR]: Invalid ContractName supplied to Helpers.getContract - ${name}`);
    return;
  }
  return contractData;
};

Helpers.getContract = ({ name, chainId = GLOBALS.DEFAULT_CHAIN_ID, signerOrProvider = null }) => {
  const contractData = Helpers.getContractData({ name });
  return new ethers.Contract(contractData.address[`${chainId}`], contractData.abi, signerOrProvider);
};

export { Helpers };
