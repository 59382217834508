// Frameworks
import React from 'react';

// Material UI
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Material UI Icons
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import PauseRounded from '@mui/icons-material/PauseRounded';

// App Contexts
import { useAudioContext } from '../contexts/audio';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('AudioControls');
log.debug('initialized');


const AudioControls = () => {
  const [ audio, audioDispatch ] = useAudioContext();

  const _toggleAudioPlayState = () => {
    audioDispatch({ type: 'UPDATE_PLAYING', payload: !audio.isPlaying });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={1}>
        <IconButton aria-label="fast rewind" disabled>
          <FastRewindRounded />
        </IconButton>
        <IconButton
          aria-label={!audio.isPlaying ? 'play' : 'pause'}
          sx={{ mx: 1 }}
          onClick={_toggleAudioPlayState}
        >
          {!audio.isPlaying ? <PlayArrowRounded /> : <PauseRounded />}
        </IconButton>
        <IconButton aria-label="fast forward" disabled>
          <FastForwardRounded />
        </IconButton>
      </Stack>
    </Box>
  );
};

export { AudioControls };
