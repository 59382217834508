// Frameworks
import React, { useEffect } from 'react';

import { useAttributes } from '../../hooks';

import AttrAddItem from './AttrAddItem';
import AttrList from './AttrList';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('AttributeList/AttrListContainer');
log.debug('initialized');

const AttrListContainer = ({ initialAttributes = [], onUpdate }) => {
  const { attributes, addAttribute, removeAttribute } = useAttributes(initialAttributes);

  useEffect(() => {
    onUpdate(attributes);
  }, [ attributes, onUpdate ]);

  return (
    <>
      <AttrList
        attributes={attributes}
        onRemove={idx => removeAttribute(idx)}
      />
      <AttrAddItem
        onAddItem={addAttribute}
      />
    </>
  );
};

export { AttrListContainer };
