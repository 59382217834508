// Frameworks
import React from 'react';

// Material UI
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

// App Contexts
import { useAudioContext } from '../contexts/audio';


const _getSliderStyles = (theme, seekable) => {
  return {
    color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
    height: 4,
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&:before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `0px 0px 0px 8px ${
          theme.palette.mode === 'dark'
            ? 'rgb(255 255 255 / 16%)'
            : 'rgb(0 0 0 / 16%)'
        }`,
      },
      '&.Mui-active': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiSlider-rail': {
      opacity: 0.28,
      '&::after': {
        display: 'block',
        position: 'absolute',
        borderRadius: 'inherit',
        backgroundColor: 'green',
        opacity: 0.65,
        width: `${seekable}%`,
        height: 'inherit',
        top: '50%',
        transform: 'translateY(-50%)',
        content: '""',
      },
    },
  };
};

const formatDuration = (value) => {
  const minute = Math.floor(value / 60);
  const secondLeft = value - minute * 60;
  return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
};

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});


const AudioSeeker = () => {
  const theme = useTheme();
  const [ audio, audioDispatch ] = useAudioContext();

  const duration = Math.floor(audio?.meta?.duration ?? 1);
  // const buffered = Math.floor(audio?.meta?.bufferedAmount ?? 0);
  // const bufferedPct = Math.floor(buffered / duration) * 100;
  const seekable = Math.floor(audio?.meta?.seekableAmount ?? 0);
  const seekablePct = Math.floor(seekable / duration) * 100;
  // console.log({ bufferedPct, seekablePct });

  const _handleSeekPositionChange = (event, value) => {
    audioDispatch({ type: 'FORCE_SEEK', payload: value });
  };

  return (
    <Box sx={{ width: '100%', minWidth: 300 }}>
      <Slider
        aria-label="time-indicator"
        size="small"
        defaultValue={audio?.seekPosition}
        value={audio.seekPosition}
        min={0}
        step={1}
        max={duration}
        onChange={_handleSeekPositionChange}
        sx={_getSliderStyles(theme, seekablePct)}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: -2,
        }}
      >
        <TinyText>{formatDuration(audio.seekPosition)}</TinyText>
        <TinyText>-{formatDuration(duration - audio.seekPosition)}</TinyText>
      </Box>
    </Box>
  );
};

export { AudioSeeker };
