// Frameworks
import React from 'react';
import _ from 'lodash';

// Material UI
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';

// App Contexts
import { useAudioContext } from '../contexts/audio';

// App Components
import { AudioControls } from './AudioControls';
import { AudioSeeker } from './AudioSeeker';
import { VolumeButton } from './VolumeButton';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('AudioPlayerFloat');
log.debug('initialized');


const AudioPlayerFloat = () => {
  const theme = useTheme();
  const [ audio, audioDispatch ] = useAudioContext();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const _toggleAudioPlayer = state => () => {
    audioDispatch({ type: 'TOGGLE_PANEL', payload: state });
  };

  const _renderToggleIcon = () => {
    return (
      <IconButton aria-label="Close" onClick={_toggleAudioPlayer(false)}>
        <MusicOffIcon />
      </IconButton>
    );
  };

  const _renderNoAudio = () => {
    if (audio.hasCurrent) { return; }
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <p>No Audio Selected</p>
        </Grid>
        <Grid item>
          {_renderToggleIcon()}
        </Grid>
      </Grid>
    );
  };

  const _renderAllControls = () => {
    if (!audio.hasCurrent) { return; }

    const music = audio?.current ?? {};
    const imgSrc = _.get(music, 'metadata.thumbnail.http', _.get(music, 'metadata.properties.coverImage.http', ''));

    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Avatar
            variant="square"
            sx={{ width: 60, height: 60, borderRadius: 1 }}
            alt={music?.metadata?.title ?? ''}
            src={imgSrc}
          />
        </Grid>
        <Grid item>
          <AudioControls />
        </Grid>
        <Grid item xs={8}>
          <AudioSeeker />
        </Grid>
        <Grid item>
          <VolumeButton />
        </Grid>
        <Grid item>
          {_renderToggleIcon()}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Zoom
        in={!audio.isFloatingPanelVisible}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${!audio.isFloatingPanelVisible ? transitionDuration.exit : 0}ms`,
        }}
      >
        <Fab
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          aria-label="Open"
          color="primary"
          size="small"
          onClick={_toggleAudioPlayer(true)}
        >
          <MusicNoteIcon />
        </Fab>
      </Zoom>
      <Slide appear={true} direction="up" in={audio.isFloatingPanelVisible}>
        <Paper
          elevation={5}
          sx={{
            position: 'fixed', bottom: 0, left: 0,
            width: '98%', mx: '1%', mb: -2, pb: 2,
            zIndex: GLOBALS.BASE_Z_INDEX + 1,
          }}
        >
          <Box sx={{ p: 1 }}>
            {_renderNoAudio()}
            {_renderAllControls()}
          </Box>
        </Paper>
      </Slide>
    </>
  );
};

export { AudioPlayerFloat };
