// Reference:
//    https://usehooks.com/

// Frameworks
import { useState, useEffect, useRef } from 'react';

// App Components
import { CarbonStorage } from '../utils/storage';


export const useLocalStorage = (key, initialValue) => {
  const { get, set } = CarbonStorage.fromLocal(key, initialValue);
  const [ storedValue, setStoredValue ] = useState(() => get());
  const setValue = (value) => {
    setStoredValue(set(value));
  };
  return [ storedValue, setValue ];
};


export const useAttributes = (initialValue = []) => {
  const [ attributes, setAttributes ] = useState(initialValue);
  return {
    attributes,
    addAttribute: (attributeData) => {
      setAttributes(attributes.concat(attributeData));
    },
    removeAttribute: (idx) => {
      setAttributes(attributes.filter((attr, index) => idx !== index));
    },
  };
};


export const useDebounce = (value, delay) => {
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [ value, delay ]);

  return debouncedValue;
};


export const useWhyDidYouUpdate = (name, props) => {
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      const changesObj = {};
      allKeys.forEach((key) => {
        if (previousProps.current[key] !== props[key]) {
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      if (Object.keys(changesObj).length) {
        console.log('[why-did-you-update]', name, changesObj);
      }
    }
    previousProps.current = props;
  });
};
