// Frameworks
import React from 'react';
import * as _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import AttrListItem from './AttrListItem';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('AttributeList/AttrList');
log.debug('initialized');

const AttrList = ({ attributes, onRemove }) => {
  if (_.size(attributes) < 1) {
    return '';
  }

  return (
    <Box>
      <List style={{ overflow: 'scroll' }}>
        {_.map(attributes, (attr, idx) => (
          <AttrListItem
            {...attr}
            key={`Attribute.${idx}`}
            onRemove={() => onRemove(idx)}
          />
        ))}
      </List>
    </Box>
  );
};

export default AttrList;
