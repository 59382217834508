// Frameworks
import React from 'react';
import { navigate } from 'gatsby';
import * as _ from 'lodash';

// Material UI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// App Components
import { useAuthContext } from '../contexts/auth';
import { ProfileMenu } from './ProfileMenu';
import { AppTitleLink } from './AppTitleLink';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('HeaderBar');
log.debug('initialized');


const HeaderBar = ({ location }) => {
  const [ authState ] = useAuthContext();
  const locationParts = _.compact(location?.pathname?.split('/'));
  _.remove(locationParts, val => _.startsWith(val, '0x'));
  const isBasepath = _.last(locationParts) === GLOBALS.APP_ROOT.replace('/', '');

  const _goHome = () => {
    navigate(GLOBALS.APP_ROOT);
  };

  const _redirectToUpload = () => {
    navigate(`${GLOBALS.APP_ROOT}/upload`);
  };

  const _renderHeaderMenu = () => {
    if (GLOBALS.IS_PRE_LAUNCH) { return; }
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          {
            !isBasepath && (
              <Button
                size="small"
                sx={{ mr: 2 }}
                startIcon={<ArrowBackIcon />}
                onClick={_goHome}
              >
                Home
              </Button>
            )
          }
          {
            authState.isLoggedIn && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                sx={{ mr: 2 }}
                onClick={_redirectToUpload}
              >
                Upload
              </Button>
            )
          }
        </Grid>
        <Grid item>
          <ProfileMenu />
        </Grid>
      </Grid>
    );
  };

  return (
    <Toolbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs="auto">
          <AppTitleLink />
        </Grid>

        <Grid item>
          {_renderHeaderMenu()}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export { HeaderBar };
