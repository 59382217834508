// Frameworks
import React from 'react';

// Material UI
import Typography from '@mui/material/Typography';

// App Queries
// import { useAuthContext } from '../contexts/auth';
// import { useMember } from '../queries/useMember';

// App Components
import SEO from '../components/seo';
import { ConnectRegisteredWallet } from '../components/ConnectRegisteredWallet';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] Wallet');
log.debug('initialized');


const Wallet = ({ location }) => {
  // const [ authState ] = useAuthContext();
  // const { isLoading, isError, isSuccess, data: currentMember } = useMember(authState?.user?.uid);
  // !isLoading && console.log('useMember', { currentMember });

  const _renderPageContent = () => {
    // if (!isSuccess) {
    //   if (isError) {
    //     // TODO...
    //   }
    //   return;
    // }
    return (
      <>
        <ConnectRegisteredWallet />

        <Typography variant="h6">Claim Earnings</Typography>
        <Typography>todo...</Typography>

        <Typography variant="h6">Change Registered Account</Typography>
        <Typography>todo...</Typography>
      </>
    );
  };

  return (
    <>
      <SEO title={'Wallet'} />

      <Typography variant="h6" align="center">Carbon Opus Wallet Management</Typography>

      {_renderPageContent()}
    </>
  );
};

export default Wallet;
