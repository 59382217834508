// Frameworks
import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import equal from 'fast-deep-equal';
import _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

// App Components
import { FileUploadButton } from '../FileUploadButton';
// import { ColorInput } from '../ColorInput';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Upload/UploadForm');
log.debug('initialized');

// Custom Styles
const _fileUploadStyles = { maxWidth: { xs: '100%', md: '60%' }, mx: 'auto', border: '1px dashed #353739' };
const _fileNameStyles = { maxWidth: '100%', color: 'text.primary' };


const UploadFiles = ({
  onChange = _.noop,
  displayErrors = false,
  formData: existingFormData,
}) => {
  const [ audioState, setAudioState ] = useState(existingFormData);

  useEffect(() => {
    if (!equal(existingFormData, audioState)) {
      const isValid = !!(audioState?.audioValid && audioState?.imageValid);
      onChange({ isValid, data: audioState });
    }
  }, [ audioState, existingFormData, onChange ]);

  const _cleanFileIconDisplay = (filename) => {
    return _.last(filename.split('\\'));
  };

  const _updateAudioFile = ({ file, fileObj, fileSize }) => {
    if (_.isEmpty(file)) { return; }

    const reader = new FileReader();
    reader.readAsArrayBuffer(fileObj);
    reader.onloadend = () => {
      const buffer = Buffer(reader.result);
      setAudioState(oldState => ({
        ...oldState,
        audio: file,
        audioSize: fileSize,
        audioMime: fileObj.type,
        audioBuffer: buffer,
        audioValid: true,
      }));
    };
    reader.onerror = (ev) => {
      alert(`Failed to read file!\n\n ${reader.error}`);
      reader.abort();
      setAudioState(oldState => ({
        ...oldState,
        audio: '',
        audioSize: '',
        audioMime: '',
        audioBuffer: null,
        audioValid: false,
      }));
    };
  };

  const _updateImageFile = ({ file, fileObj, fileSize, fileDimensions }) => {
    if (_.isUndefined(file)) { return; }

    const reader = new FileReader();
    reader.readAsArrayBuffer(fileObj);
    reader.onloadend = () => {
      const buffer = Buffer(reader.result);
      setAudioState(oldState => ({
        ...oldState,
        image: file,
        imageSize: fileSize,
        imageMime: fileObj.type,
        imageDimensions: fileDimensions,
        imageBuffer: buffer,
        imageBase64: `data:${fileObj.type};base64,${buffer.toString('base64')}`,
        imageValid: true,
      }));
    };
    reader.onerror = (ev) => {
      alert(`Failed to read file!\n\n ${reader.error}`);
      reader.abort();
      setAudioState(oldState => ({
        ...oldState,
        image: '',
        imageSize: '',
        imageMime: '',
        imageDimensions: {},
        imageBuffer: null,
        imageBase64: null,
        imageValid: false,
      }));
    };
  };

  // const _handleHasBgColor = (event) => {
  //   const { checked } = event.target;
  //   setAudioState(oldState => ({
  //     ...oldState,
  //     hasBgColor: checked,
  //     bgColor: checked ? oldState.bgColor : '',
  //   }));
  // };

  // const _handleColorChange = ({ hex }) => {
  //   setAudioState(oldState => ({
  //     ...oldState,
  //     bgColor: hex,
  //   }));
  // };

  const _renderAudioUpload = () => {
    if (_.isEmpty(audioState.audio)) {
      return (
        <>
          <Typography component="p" variant="body2">
            <strong>Supported Types: WAV, AIFF, FLAC, AAC, MP3, OGG, WMA</strong><br />
          </Typography>
          <Typography component="p" variant="caption">
            We recommend files under 10mb
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography component="p" variant="body2" sx={_fileNameStyles}>
          <strong>{_cleanFileIconDisplay(audioState.audio)}</strong><br />
        </Typography>
        <Typography component="p" variant="caption">
          ({audioState.audioSize})
        </Typography>
      </>
    );
  };

  const _renderImageUpload = () => {
    if (_.isEmpty(audioState.image)) {
      return (
        <>
          <Typography component="p" variant="body2">
            <strong>Supported Types: GIF, JPG, PNG</strong><br />
          </Typography>
          <Typography component="p" variant="caption">
            We recommend files under 10mb
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography component="p" variant="body2" sx={_fileNameStyles}>
          <strong>{_cleanFileIconDisplay(audioState.image)}</strong><br />
        </Typography>
        <Typography component="p" variant="caption">
          {audioState.imageDimensions.width} x {audioState.imageDimensions.height} &nbsp; ({audioState.imageSize})
        </Typography>
      </>
    );
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Paper elevation={0} sx={_fileUploadStyles}>
          <Box px={5} py={4}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item md={7} xs={12}>
                {_renderAudioUpload()}
              </Grid>

              <Grid item md={5} xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormControl
                    required
                    error={displayErrors && !audioState.audioValid}
                    component="fieldset"
                  >
                    <FormGroup>
                      <FileUploadButton
                        text={_.isEmpty(audioState.audio) ? 'Choose audio file' : 'Change audio file'}
                        name="uploadAudioFile"
                        accept="audio/*"
                        onFileSelect={_updateAudioFile}
                      />
                    </FormGroup>
                    <FormHelperText error={true}>{(displayErrors && !audioState.audioValid) ? 'File Audio required' : ''}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper elevation={0} sx={_fileUploadStyles}>
          <Box px={5} py={4}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item md={7} xs={12}>
                {_renderImageUpload()}
              </Grid>

              <Grid item md={5} xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormControl
                    required
                    error={displayErrors && !audioState.imageValid}
                    component="fieldset"
                  >
                    <FormGroup>
                      <FileUploadButton
                        text={_.isEmpty(audioState.image) ? 'Choose image file' : 'Change image file'}
                        name="uploadImageFile"
                        accept="image/*"
                        onFileSelect={_updateImageFile}
                      />
                    </FormGroup>
                    <FormHelperText error={true}>{(displayErrors && !audioState.imageValid) ? 'File Image required' : ''}</FormHelperText>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>

      {/* <Grid item xs={12}>
        <Box pt={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                checked={audioState.hasBgColor}
                onChange={_handleHasBgColor}
                color="primary"
                name="backgroundColor"
                inputProps={{ 'aria-label': 'use background color' }}
              />
            }
            label="Background Color"
          />
        </Box>
        {
          audioState.hasBgColor && (
            <Box pt={1} pb={2}>
              <ColorInput
                id="backgroundColor"
                initialColor={audioState.fileBgColor}
                onChange={_handleColorChange}
                alignItems="center"
                justifyContent="center"
                label="Background"
              />
            </Box>
          )
        }
      </Grid> */}


    </Grid>
  );
};

export { UploadFiles };
