// Frameworks
import React, { useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

// Material UI
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('SearchBar');
log.debug('initialized');


const useCustomStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    // width: '25ch',
  },
}));


const SearchBar = ({ onSearch = _.noop }) => {
  const customClasses = useCustomStyles();

  const [ inputValue, setInputValue ] = useState('');

  const _handleSearchChange = (event) => {
    setInputValue(event.currentTarget.value);
  };

  const _handleSearch = (event) => {
    onSearch(inputValue);
  };

  return (
    <>
      <FormControl fullWidth className={clsx(customClasses.margin, customClasses.textField)} variant="outlined">
        <InputLabel htmlFor="search-input">Search by Title, Artist or Genre</InputLabel>
        <OutlinedInput
          id="search-input"
          type="text"
          value={inputValue}
          onChange={_handleSearchChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="search"
                onClick={_handleSearch}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          // labelWidth={200}
        />
      </FormControl>

      <p>{inputValue}</p>
    </>
  );
};

export { SearchBar };
