// Frameworks
import React from 'react';

// App Components
import SEO from '../components/seo';
import { PreLaunchLander } from '../components/PreLaunchLander';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] PreLaunch');
log.debug('initialized');

const PreLaunch = () => {
  return (
    <>
      <SEO title={'Carbon Opus - Decentralized Music'} />
      <PreLaunchLander />
    </>
  );
};

export default PreLaunch;
