// Frameworks
import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';

// Material UI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('AttributeList/AttrAddItem');
log.debug('initialized');

// Custom Styles
const useCustomStyles = makeStyles(theme => ({
  addButton: {
    height: '100%',
    color: theme.palette.common.ghostWhite,
  },
}));

const AttrAddItem = ({ onAddItem }) => {
  const customClasses = useCustomStyles();

  const [ name, setName ] = useState('');
  const [ value, setValue ] = useState('');
  const [ formValidated, setFormValidated ] = useState(false);
  const [ isNameValid, setNameValid ] = useState(true);
  const [ isValueValid, setValueValid ] = useState(true);

  const valueLabel = 'Attribute value...';

  useEffect(() => {
    const _validateForm = () => {
      const conditions = [ !_.isEmpty(name), !_.isEmpty(value) ];
      return _.every(conditions, Boolean);
    };
    setFormValidated(_validateForm());
  }, [ name, value ]);

  const _getFormData = () => {
    return { name, value };
  };

  const _validateAll = () => {
    setNameValid(!_.isEmpty(name));
    setValueValid(!_.isEmpty(value));
  };

  const _updateName = (evt) => {
    const val = evt.target.value;
    setName(val);
    setNameValid(!_.isEmpty(val));
  };

  const _updateValue = (evt) => {
    const val = evt.target.value;
    setValue(val);
    setValueValid(!_.isEmpty(val));
  };

  const _addItem = () => {
    if (!formValidated) {
      return _validateAll();
    }
    onAddItem(_getFormData());
    setName('');
    setValue('');
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid xs={5} item>
          <TextField
            id="attributeName"
            placeholder="Attribute name..."
            variant="standard"
            size="small"
            onChange={_updateName}
            value={name}
            fullWidth
            required
            error={!isNameValid}
          />
        </Grid>
        <Grid xs={5} item>
          <TextField
            id="attributeValue"
            placeholder={valueLabel}
            variant="standard"
            size="small"
            type="text"
            value={value}
            onChange={_updateValue}
            fullWidth
            required
            error={!isValueValid}
          />
        </Grid>
        <Grid xs={2} item>
          <Button
            fullWidth
            startIcon={<AddIcon />}
            className={customClasses.addButton}
            onClick={_addItem}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttrAddItem;
