// Frameworks
import { useState } from 'react';
// import { NFTStorage, File } from 'nft.storage';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import _ from 'lodash';

// App Components
import { getStorage } from '../firebase';
import { useAuthContext } from '../contexts/auth';
import { saveMusicNft } from '../firebase/saveMusicNft';
import { invalidateQueries } from '../queries';
import { Helpers } from '../utils/helpers';
import { GLOBALS } from '../utils/globals';

const _defaultProgressState = {
  audio: 0,
  cover: 0,
  thumb: 0,
  meta: 0,
  db: 0,
};

const useNftStorage = () => {
  const [ authState ] = useAuthContext();

  const [ isNftLoading,    setIsNftLoading ]    = useState(false);
  const [ isNftError,      setIsNftError ]      = useState(false);
  const [ isNftSuccess,    setIsNftSuccess ]    = useState(false);
  const [ nftLoadingState, setNftLoadingState ] = useState(_defaultProgressState);
  const [ nftResult,       setNftResult ]       = useState({});
  const [ nftErrors,       setNftErrors ]       = useState(null);

  const _resetState = () => {
    setIsNftLoading(() => false);
    setIsNftError(() => false);
    setIsNftSuccess(() => false);
    setNftResult(() => ({}));
    setNftLoadingState(() => _defaultProgressState);
    setNftErrors(() => null);
  };

  const _uploadFileToStorage = (memberId, file, mime, buffer, statusCallback = _.noop) => {
    return new Promise((resolve, reject) => {
      (async () => {
        const storage = getStorage().storage;
        const fileName = _.last(file.split('\\'));
        const metadata = { contentType: mime };
        const storageRefId = `${memberId}/${Helpers.uuid()}/${fileName}`;
        const storageRef = ref(storage, storageRefId);
        const uploadTask = uploadBytesResumable(storageRef, buffer, metadata);

        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            statusCallback(progress);
          },
          (error) => {
            // A full list of error codes is available at https://firebase.google.com/docs/storage/web/handle-errors
            reject(new Error(error));
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              statusCallback(100);
              resolve({
                storageRefId,
                fileName,
                downloadURL,
              });
            });
          },
        );
      })();
    });
  };

  const _uploadCoverImage = (memberId, formData) => {
    setNftLoadingState(old => ({ ...old, cover: 0 }));
    return _uploadFileToStorage(
      memberId,
      formData.image,
      formData.imageMime,
      formData.imageBuffer,
      progress => setNftLoadingState(old => ({ ...old, cover: progress })),
    );
  };

  const _uploadThumbnailImage = (memberId, formData) => {
    setNftLoadingState(old => ({ ...old, thumb: 0 }));
    const imageName = _.last(formData.image.split('\\'));
    let thumbnailBuffer;
    (async () => {
      const { readAndCompressImage } = require('browser-image-resizer');
      const thumbnailBlob = new Blob([ new Uint8Array(formData.imageBuffer) ]);
      thumbnailBuffer = await readAndCompressImage(thumbnailBlob, {
        quality: GLOBALS.NFT.THUMBNAIL.QUALITY,
        maxWidth: GLOBALS.NFT.THUMBNAIL.MAX_WIDTH,
      });
      // const thumbnail = new File([ thumbnailBuffer ], `thumbnail-${imageName}`, { type: formData.imageMime });
      // const thumbResults = await client.storeBlob(thumbnail);
    })();

    return _uploadFileToStorage(
      memberId,
      `thumbnail-${imageName}`,
      formData.imageMime,
      thumbnailBuffer,
      progress => setNftLoadingState(old => ({ ...old, thumb: progress })),
    );
  };

  const _uploadAudioFile = (memberId, formData) => {
    setNftLoadingState(old => ({ ...old, audio: 0 }));

    return _uploadFileToStorage(
      memberId,
      formData.audio,
      formData.audioMime,
      formData.audioBuffer,
      progress => setNftLoadingState(old => ({ ...old, audio: progress })),
    );
  };

  const _generateMetadata = async (audio, image, thumbnail, formData) => {
    setNftLoadingState(old => ({ ...old, meta: 0 }));

    // const client = new NFTStorage({ token: GLOBALS.NFT_STORAGE_TOKEN });
    // const imageName = _.last(formData.image.split('\\'));
    // const image = new File([ formData.imageBuffer ], imageName, { type: formData.imageMime });

    // const { readAndCompressImage } = require('browser-image-resizer');
    // const thumbnailBlob = new Blob([ new Uint8Array(formData.imageBuffer) ]);
    // const thumbnailBuffer = await readAndCompressImage(thumbnailBlob, {
    //   quality: GLOBALS.NFT.THUMBNAIL.QUALITY,
    //   maxWidth: GLOBALS.NFT.THUMBNAIL.MAX_WIDTH,
    // });
    // const thumbnail = new File([ thumbnailBuffer ], `thumbnail-${imageName}`, { type: formData.imageMime });
    // const thumbResults = await client.storeBlob(thumbnail);

    const nftMetadata = {
      title: formData.title,
      artist: formData.artist,
      hasCustomArtist: formData.hasCustomArtist,
      genre: formData.genre,
      subgenre: formData.subgenre,
      description: formData.desc,
      image: image.downloadURL,
      thumbnail: {
        storage: thumbnail.downloadURL,
      },
      attributes: [ ...formData.attributes ],
      properties: {
        audio: {
          audioName: audio.fileName,
          audioMime: formData.audioMime,
          audioSize: formData.audioSize,
          storageRefId: audio.storageRefId,
          http: audio.downloadURL,
        },
        coverImage: {
          imageName: image.fileName,
          imageMime: formData.imageMime,
          imageSize: formData.imageSize,
          imageDimensions: formData.imageDimensions,
          storageRefId: image.storageRefId,
          http: image.downloadURL,
        },
        thumbnailImage: {
          imageName: thumbnail.fileName,
          imageMime: formData.imageMime,
          imageDimensions: { width: GLOBALS.NFT.THUMBNAIL.MAX_WIDTH },
          storageRefId: thumbnail.storageRefId,
          http: thumbnail.downloadURL,
        },
        backgroundColor: formData.bgColor,
        salePrice: formData.salePrice,
        resaleRoyalty: formData.resaleRoyalty,
      },
    };

    // Upload NFT Metadata to NFT.Storage (IPFS)
    // const storeResults = await client.store(nftMetadata);

    // Metadata URL
    // nftMetadata.ipfs = storeResults.url;
    // nftMetadata.http = `https://nftstorage.link/ipfs/${storeResults.url.replace('ipfs://', '')}`;

    // Update Image Property
    // nftMetadata.image = storeResults.data.image.href;
    // nftMetadata.properties.coverImage = {
    //   ipfs: storeResults.data.image.href,
    //   http: `https://nftstorage.link/ipfs/${storeResults.data.image.href.replace('ipfs://', '')}`,
    // };

    // Update Image Thumbnail
    // nftMetadata.thumbnail.ipfs = `ipfs://${thumbResults}`;
    // nftMetadata.thumbnail.http = `https://nftstorage.link/ipfs/${thumbResults}`;
    setNftLoadingState(old => ({ ...old, meta: 100 }));
    return nftMetadata;
  };

  const _saveNft = async (metadata) => {
    setNftLoadingState(old => ({ ...old, db: 0 }));
    const results = await saveMusicNft({ metadata });
    setNftLoadingState(old => ({ ...old, db: 100 }));
    return results;
  };

  const storeNFT = async (formData) => {
    const memberId = authState?.user?.uid;
    if (_.isEmpty(memberId)) { return; }
    try {
      setIsNftLoading(() => true);

      const [ audio, coverImage, thumbnailImage ] = await Promise.all([
        _uploadAudioFile(memberId, formData),
        _uploadCoverImage(memberId, formData),
        _uploadThumbnailImage(memberId, formData),
      ]);

      const metadata = await _generateMetadata(audio, coverImage, thumbnailImage, formData);
      const savedMetadata = await _saveNft(metadata);

      setIsNftSuccess(() => true);
      setNftResult(() => _.get(savedMetadata, 'data.data', {}));
      invalidateQueries([ 'memberMusic' ]);
    } catch (err) {
      setIsNftError(() => true);
      setNftErrors(() => err);
    } finally {
      setIsNftLoading(() => false);
    }
  };

  const resetNFT = () => {
    _resetState();
  };

  return {
    isNftLoading,
    isNftError,
    isNftSuccess,
    storeNFT,
    resetNFT,
    nftLoadingState,
    nftResult,
    nftErrors,
  };
};

export { useNftStorage };
