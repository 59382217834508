// Frameworks
import React, { createContext, useContext, useReducer, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import * as _ from 'lodash';

// App Components
// import { invalidateQueries } from '../utils/queries';
import { ContractFactory } from '../web3/ContractFactory';
import { useWeb3Context } from './web3';


const _CLEAR_TX_DELAY = 2000;

const initialState = {
  status: 'None',
  txData: {},
  txReceipt: {},
  errors: null,
};
export const TransactionContext = createContext(initialState);

export function useTransactionContext() {
  return useContext(TransactionContext);
}


const TransactionReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_TX_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_TX_STATE':
      return {
        ...state,
        status: 'None',
        txData: {},
        txReceipt: {},
        errors: null,
      };
    default:
      return state;
  }
};

export default function Provider({ children }) {
  const [ state, dispatch ] = useReducer(TransactionReducer, initialState);
  const contractFactory = ContractFactory.instance();
  contractFactory.updateTxDispatcher(dispatch);
  return (
    <TransactionContext.Provider value={[ contractFactory, state, dispatch ]}>
      {children}
    </TransactionContext.Provider>
  );
}

export function Updater() {
  const [ web3 ] = useWeb3Context();
  const [ , txState, txDispatch ] = useTransactionContext();
  const { enqueueSnackbar } = useSnackbar();
  const currentTxState = useRef();

  useEffect(() => {
    const instance = ContractFactory.instance();
    if (web3.readProvider) {
      instance.updateWeb3(web3);
      instance.watchExistingTransactions();
    }
    if (web3.writeProvider) {
      instance.updateSigner(web3);
    }
  }, [ web3 ]);


  useEffect(() => {
    (async () => {
      const { status, txData, txReceipt } = txState;
      if (currentTxState.current === status) { return; }
      currentTxState.current = status;

      if (status === 'Success') {
        enqueueSnackbar(`${txData.txTitle} Transaction Complete!`, { variant: 'success' });

        // Mark Item as Sold in DB
        // void await _updateDbForToken({ txData, txReceipt });
        console.log({ txReceipt });

        // invalidateQueries([ 'internalMetadata', 'userNfts' ]);

        setTimeout(() => {
          txDispatch({ type: 'CLEAR_TX_STATE' });
        }, _CLEAR_TX_DELAY);
      }

      if (status === 'Fail') {
        console.error('TX Failed: ', { txState });
        enqueueSnackbar(`${txData.txTitle} Transaction Failed!`, { variant: 'error' });
      }

      if (status === 'Exception') {
        console.error('TX Exception: ', _.get(txState, 'errors.0.errorMessage', 'Unknown'));
      }
    })();
  }, [ txState, txDispatch, enqueueSnackbar ]);

  return null;
}
