// Frameworks
import React from 'react';
import { Link } from 'gatsby';

// Material UI
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

// App Contexts
import { useAuthContext } from '../contexts/auth';

// App Components
import { Loading } from '../components/Loading';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('ProtectedRoute');
log.debug('initialized');


const ProtectedRoute = ({ component: Component, location, redirect = { label: 'Home Page', link: '/home' } }) => {
  const [ authState ] = useAuthContext();

  if (authState.isLoading) {
    return (<Loading />);
  }

  if (!authState.isLoading && !authState.isLoggedIn) {
    return (
      <Alert severity="error" variant="outlined">
        <AlertTitle>Not Authenticated!</AlertTitle>
        You must be authenticated to view ths page. <br/>
        <Link to={`${GLOBALS.APP_ROOT}${redirect.link}`}>
          {redirect.label}
        </Link>
      </Alert>
    );
  }

  return (
    <Component location={location} />
  );
};

export default ProtectedRoute;
