// Frameworks
import React, { useState, useEffect } from 'react';
// import _ from 'lodash';

// Material UI
import Typography from '@mui/material/Typography';


const WordRotator = ({ wordList, delay = 2500, variant = 'body1', sx }) => {
  const [ wordIndex, setWordIndex ] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      console.log('GO');
      setWordIndex((currentIndex) => {
        return (currentIndex < wordList.length - 1) ? currentIndex + 1 : 0;
      });
    }, delay);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Typography variant={variant} sx={sx}>
      {wordList[wordIndex]}
    </Typography>
  );
};

export { WordRotator };
