// Frameworks
import React from 'react';
import * as _ from 'lodash';

// Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

// App Components
import { useEns } from '../hooks/useEns';
import { Helpers } from '../utils/helpers';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('FriendlyAddress');
log.debug('initialized');


const FriendlyAddress = ({
  address,
  variant = 'standard',
  digits = 6,
  separator = ' ... ',
  prefix = '0x',
  size = 'small',
  sx = {},
  onAddressClick = _.noop,
}) => {
  const { isLoading, ensName } = useEns(address);

  const renderVariantStandard = () => {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Button size={size} onClick={onAddressClick}>{Helpers.getFriendlyAddress({ address, digits, separator, prefix })}</Button>
        {
          isLoading
            ? (<CircularProgress color="secondary" size={16} />)
            : (!_.isEmpty(ensName) ? (<Chip size={size} variant="outlined" label={`ENS: ${ensName}`}></Chip>) : '')
        }
      </Stack>
    );
  };

  const renderVariantPaper = () => {
    return (
      <Paper>
        {renderVariantStandard()}
      </Paper>
    );
  };


  let content = '';
  switch (variant) {
    case 'paper':
      content = renderVariantPaper();
      break;
    default:
      content = renderVariantStandard();
      break;
  }
  return (
    <Box sx={sx}>
      {content}
    </Box>
  );
};

export { FriendlyAddress };
