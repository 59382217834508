// Frameworks
import { useQuery } from 'react-query';
import axios from 'axios';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from './index';
import { GLOBALS } from '../utils/globals';


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query
const queryFn = memberIdentifier => async () => {
  console.log(GLOBALS.CARBONOPUS_API_URL);
  return _transformResponse(await axios({
    url: `/members/${memberIdentifier}`,
    method: 'get',
    baseURL: GLOBALS.CARBONOPUS_API_URL,
  }), { memberIdentifier });
};

// Custom Hook
//  identifier may be either "memberId" or "screenName"
const useMember = (memberIdentifier = null) => {
  const enabled = !_.isEmpty(memberIdentifier);
  return useQuery(
    [ 'useMember', memberIdentifier ],
    queryFn(memberIdentifier),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformResponse = (response, extraData) => {
  return { ...extraData, ...response.data.data };
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time
setQueryDefaults([ 'useMember' ], { staleTime: 60 * 1000 * 60 }); // 60 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'useMember' ])


// Export Hook
export { useMember };
