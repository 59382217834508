import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import * as _ from 'lodash';

import { useWeb3Context } from '../contexts/web3';
import { useLocalStorage } from './index';

const useEns = (nameOrAddress) => {
  const [ web3 ] = useWeb3Context();
  const [ storedEns, setStoredEns ] = useLocalStorage('cachedEnsData', '');
  const [ ensName, setENSName ] = useState(null);
  const [ ensAddress, setENSAddress ] = useState(null);
  const [ ensAvatar, setENSAvatar ] = useState(null);
  const [ isLoading, setLoading ] = useState(false);
  const [ isValid, setIsValid ] = useState(true);

  useEffect(() => {
    const resolveENS = async () => {
      try {
        setLoading(true);
        const cachedData = storedEns[_.toLower(nameOrAddress)];
        let validAddress = !_.isEmpty(nameOrAddress) && ethers.utils.isAddress(nameOrAddress);
        let foundAddress = '';
        if (validAddress) {
          setIsValid(true);
          setENSAddress(nameOrAddress);
          let name = cachedData;
          if (_.isEmpty(name)) {
            name = await web3.readProvider.lookupAddress(nameOrAddress);
            if (!_.isEmpty(name)) {
              setStoredEns({ ...storedEns, [nameOrAddress]: name });
            }
          }
          foundAddress = nameOrAddress;
          setENSName(name);
        } else {
          setENSAddress('');
          setENSName('');
          if (_.endsWith(nameOrAddress, '.eth')) {
            foundAddress = cachedData;
            if (_.isEmpty(foundAddress)) {
              foundAddress = await web3.readProvider.resolveName(nameOrAddress);
              if (!_.isEmpty(foundAddress)) {
                setStoredEns({ ...storedEns, [nameOrAddress]: foundAddress });
              }
            }
            validAddress = !_.isEmpty(foundAddress);
            setENSAddress(foundAddress);
            setENSName(nameOrAddress);
            setIsValid(validAddress);
          }
        }

        if (validAddress) {
          const resolver = await web3.readProvider.getResolver(foundAddress) || null;
          const avatar = resolver ? await resolver.getAvatar() : { url: '' };
          setENSAvatar(avatar?.url);
        }
      } finally {
        setLoading(false);
      }
    };

    if (!web3.readProvider) { return; }
    resolveENS();
  }, [ nameOrAddress, web3.readProvider ]); // eslint-disable-line

  return { isLoading, isValid, ensAddress, ensName, ensAvatar };
};

export { useEns };
