// Frameworks
import * as _ from 'lodash';

// App Components
import { getState } from './index';
import { useUserTokenBalances } from './useUserTokenBalances';
import { useUserTokenAllowances } from './useUserTokenAllowances';
import { useTokenMetadata } from './useTokenMetadata';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Hook
const useUserTokens = (userAddress, chainId) => {
  const userTokenBalances = useUserTokenBalances(userAddress, chainId);
  const tokenAddresses = _.map(userTokenBalances.data, 'contractAddress');
  const userTokenAllowances = useUserTokenAllowances(userAddress, tokenAddresses, chainId);
  const tokenMetadata = useTokenMetadata(tokenAddresses, chainId);

  // Validate all data-sources
  const dataSources = [ userTokenBalances, userTokenAllowances, tokenMetadata ];
  if (!_.every(dataSources, 'isSuccess')) {
    if (_.some(dataSources, 'isError')) {
      const errors = _.compact(_.map(dataSources, (result) => {
        if (!result.isError) return;
        return result.error;
      }));
      return getState({ isError: true, errors });
    }
    return getState({ isLoading: true });
  }

  // Merge all results and return
  return getState({ isSuccess: true, data: _transformTokenData(
    userTokenBalances,
    userTokenAllowances,
    tokenMetadata,
  ) });
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformTokenData = (userTokenBalances, userTokenAllowances, tokenMetadata) => {
  return _.map(userTokenBalances.data, (token) => {
    const metadata = _.find(tokenMetadata.data, [ 'tokenAddress', token.contractAddress ]) || {};
    const allowance = _.find(userTokenAllowances.data, [ 'tokenAddress', token.contractAddress ]) || {};
    return {
      contractAddress: token.contractAddress,
      balance: token.balance,
      balanceHex: token.tokenBalance,
      allowance: allowance.allowance,
      decimals: metadata.decimals,
      name: metadata.name,
      symbol: metadata.symbol,
      logo: metadata.logo,
    };
  });
};

// Export Hook
export { useUserTokens };
