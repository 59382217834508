// Frameworks
import React from 'react';
// import * as _ from 'lodash';

// Material UI
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('AttributeList/AttrListItem');
log.debug('initialized');


const AttrListItem = ({ name, value, onRemove }) => (
  <ListItem>
    <ListItemIcon>
      <CheckIcon color="primary" />
    </ListItemIcon>
    <ListItemText primary={`${name}: ${value}`} />
    <ListItemSecondaryAction>
      <IconButton aria-label="Delete Attribute" onClick={onRemove}>
        <DeleteOutlined />
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

export default AttrListItem;
