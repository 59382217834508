import React, { useEffect } from 'react';
import { Router } from '@reach/router';

// React-Query
import { QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import ReactQueryClient from './queries';

// Firebase
import CarbonFirebase from './firebase';

// Material UI Styles
import theme from './layout/styles/root.theme';
import { ThemeProvider } from '@mui/material/styles';
import './layout/styles/overrides.css';

// Custom Snackbar
import { SnackbarProvider } from 'notistack';

// Common
import { GLOBALS } from './utils/globals';
import { Helpers } from './utils/helpers';

// Data Contexts
import Web3ContextProvider, { Updater as Web3ContextUpdater } from './contexts/web3';
import AuthContextProvider, { Updater as AuthContextUpdater } from './contexts/auth';
import AudioContextProvider, { Updater as AudioContextUpdater } from './contexts/audio';
import TxContextProvider, { Updater as TxContextUpdater } from './contexts/transactions';
import FiltersContextProvider, { Updater as FiltersContextUpdater } from './contexts/filters';

// Page Templates
import AppLayout from './layout/AppLayout';
import ProtectedRoute from './pages/ProtectedRoute';
import PreLaunch from './pages/PreLaunch';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Wallet from './pages/Wallet';
import Upload from './pages/Upload';
import Settings from './pages/Settings';
import Music from './pages/Music';


function AppContexts({ children }) {
  return (
    <Web3ContextProvider>
      <AuthContextProvider>
        <AudioContextProvider>
          <FiltersContextProvider>
            <TxContextProvider>
              {children}
            </TxContextProvider>
          </FiltersContextProvider>
        </AudioContextProvider>
      </AuthContextProvider>
    </Web3ContextProvider>
  );
}

function AppUpdaters() {
  useEffect(() => {
    CarbonFirebase.instance();
    Helpers.hideInitialLoading();
    setTimeout(() => Helpers.hideInitialLoading(), 5000);
    setTimeout(() => Helpers.hideInitialLoading(), 10000);
    setTimeout(() => Helpers.hideInitialLoading(), 15000);
  }, []);

  return (
    <>
      <Web3ContextUpdater />
      <AuthContextUpdater />
      <AudioContextUpdater />
      <FiltersContextUpdater />
      <TxContextUpdater />
    </>
  );
}

function App({ location }) {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={ReactQueryClient.instance()}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          iconVariant={{
            success: '✅ ',
            error: '❌ ',
            warning: '❗ ',
            info: '🔹 ',
          }}
          // TransitionComponent={Slide}
        >
          <AppContexts>
            <AppUpdaters />
            <AppLayout location={location}>
              <Router basepath={GLOBALS.APP_ROOT}>
                {
                  GLOBALS.IS_PRE_LAUNCH
                    ? <PreLaunch path={GLOBALS.APP_ROOT} default />
                    : (<>
                      <Home path={GLOBALS.APP_ROOT} default />
                      <Music path="/music/:musicId" />
                      <Profile path="/profile" />
                      <Profile path="/profile/:memberIdentifier" />
                      <ProtectedRoute path="/wallet" component={Wallet} />
                      <ProtectedRoute path="/upload" component={Upload} />
                      <ProtectedRoute path="/settings" component={Settings} />
                    </>)
                }
              </Router>
            </AppLayout>
          </AppContexts>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
