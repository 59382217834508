// Frameworks
import React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// App Components
import SEO from '../components/seo';
import { UploadSteps } from '../components/upload/UploadSteps';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] Upload');
log.debug('initialized');


const Upload = () => {
  return (
    <>
      <SEO title={'Upload'} />

      <Typography variant="h6" align="center">Upload Music File</Typography>

      <Box sx={{ m: 2 }}>
        <UploadSteps />
      </Box>
    </>
  );
};

export default Upload;
