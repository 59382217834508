// Frameworks
import React, { useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { ethers } from 'ethers';
import _ from 'lodash';
import window from 'global';

// Material UI
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// App Hooks
import { invalidateQueries } from '../queries';
import { useWeb3Context } from '../contexts/web3';
import { useAuthContext } from '../contexts/auth';
import { useTransactionContext } from '../contexts/transactions';
import { useMember } from '../queries/useMember';
import { useUserBalance } from '../queries/useUserBalance';
import { useUserTokens } from '../queries/useUserTokens';

// App Database
import { updateRegisteredWallet } from '../firebase/updateRegisteredWallet';

// App Components
import { Loading } from './Loading';
import { FriendlyAddress } from './FriendlyAddress';
import { getFriendlyChainNameById } from '../web3/getChainNameById';
import { switchWalletNetwork } from '../web3/switchWalletNetwork';
// import { Helpers } from '../utils/helpers';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('ConnectRegisteredWallet');
log.debug('initialized');


// const addressBlockStyles = {
//   display: 'inline-block',
//   ...theme.typography.button,
//   backgroundColor: theme.palette.grey[200],
//   borderRadius: theme.shape.borderRadius,
//   padding: theme.spacing(1),
//   marginTop: theme.spacing(1),
//   marginBottom: theme.spacing(2),
// };

const ConnectRegisteredWallet = ({ location }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [ authState ] = useAuthContext();
  const { isSuccess, data: currentMember } = useMember(authState?.user?.uid);
  const [ web3, , connect, disconnect ] = useWeb3Context();
  const [ contractFactory, txState ] = useTransactionContext();

  const userBalance = useUserBalance(web3.connectedAccount, web3.chainId);
  const userTokens = useUserTokens(web3.connectedAccount, web3.chainId);
  // console.log('ConnectRegisteredWallet', {userBalance, userTokens, web3.connectedAccount});

  const [ registeredWallet, setRegisteredWallet ] = useState('');
  const [ isApiLoading, setApiLoading ] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const wallet = _.get(currentMember, 'data.registeredWallet', '');
      setRegisteredWallet(wallet);
    }
  }, [ isSuccess, currentMember, setRegisteredWallet ]);


  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // TEST TX:

  const [ lastMintedTokenId, setLastMintedTokenId ] = useState(0);
  const isPending = useMemo(() => (txState?.status === 'Pending'), [ txState ]);
  const isMining = useMemo(() => (txState?.status === 'Mining'), [ txState ]);

  useEffect(() => {
    if (txState?.status === 'Success') {
      setLastMintedTokenId(parseMintedTokenId(txState.txReceipt));
    }
  }, [ txState, setLastMintedTokenId ]);

  const parseMintedTokenId = (txReceipt) => {
    const foundEvent = _.find(txReceipt.events, { 'event': 'NewTokenMinted' });
    if (foundEvent) {
      return ethers.BigNumber.from(foundEvent.args[1]).toNumber();
    }
    return 0;
  };

  const _testTransaction = async () => {
    const txTitle = 'Mint NFT';
    const txOverrides = {};
    const startingPrice = 0.5;
    const resellerPercent = 20;
    const startingPriceWei = ethers.utils.parseEther(`${startingPrice}`);
    const resellerPercentWei = `${(resellerPercent * 100)}`;

    // With Token URI
    // const tokenUri = 'https://nftstorage.link/ipfs/';
    // void contractFactory.sendTransaction('carbonOpus', 'mintNftWithURI', txTitle, {}, { startingPriceWei, resellerPercentWei, tokenUri });

    // Without Token URI
    void contractFactory.sendTransaction('carbonOpus', 'mintNft', txTitle, txOverrides, { startingPriceWei, resellerPercentWei });
  };

  //
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


  const _registerWallet = async () => {
    try {
      setApiLoading(true);
      await updateRegisteredWallet({ registeredWallet: web3.connectedAccount });
      invalidateQueries([ 'useMember' ]);
      enqueueSnackbar('Wallet Registered!', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Update Failed!', {
        variant: 'error',
        action: () => (<Button onClick={() => { enqueueSnackbar(e.message); }}>details</Button>),
      });
    } finally {
      setApiLoading(false);
    }
  };

  const _connectWallet = (clearCache = false) => async () => {
    await connect(clearCache);
  };

  const _disconnectWallet = async () => {
    await disconnect();
  };

  const _switchNetwork = () => {
    switchWalletNetwork(GLOBALS.DEFAULT_CHAIN_ID);
  };

  const _getUserTokenBalance = (tokenSymbol) => {
    const tokenData = _.find(userTokens.data, { symbol: tokenSymbol });
    if (!tokenData) { return '0'; }
    const balance = _.get(tokenData, 'balance', '0');
    const decimals = _.get(tokenData, 'decimals', 18);
    return ethers.utils.formatUnits(ethers.BigNumber.from(balance), decimals);
  };

  const _renderConnectionSteps = () => {
    let nextSteps = null;

    if (_.isEmpty(registeredWallet)) {
      nextSteps = (
        <Typography>Register a wallet with your account!</Typography>
      );
    } else if (registeredWallet !== web3.connectedAccount) {
      nextSteps = (
        <Typography>Connect to the same wallet you registered with your account!</Typography>
      );
    } else if (GLOBALS.DEFAULT_CHAIN_ID !== web3.chainId) {
      nextSteps = (
        <>
          <Typography>Connect your wallet to the {getFriendlyChainNameById(GLOBALS.DEFAULT_CHAIN_ID)} Network!</Typography>
          {
            window.ethereum && (
              <Button size="small" variant="contained" color="primary" onClick={_switchNetwork}>Switch Network</Button>
            )
          }
        </>
      );
    }

    // Display Next Steps
    if (nextSteps) {
      return (
        <Alert severity="warning">
          <Typography variant="h6">Next Steps:</Typography>
          {nextSteps}
        </Alert>
      );
    }

    // Everything Connected Properly
    return (
      <Alert severity="success">
        <Typography variant="h6">You&apos;re Connected!</Typography>
        <Typography>You have completed all connection steps -- You&apos;re ready to go!</Typography>
      </Alert>
    );
  };

  const _renderConnectWallet = () => {
    return (
      <>
        <Typography variant="h6">Connected Wallet:</Typography>
        {
          _.isEmpty(web3.connectedAccount)
            ? (<Button color="primary" variant="contained" onClick={_connectWallet()}>Connect Wallet</Button>)
            : (
              <>
                <Typography>- Chain ID: {web3.chainId}</Typography>
                <Typography>- Account:</Typography>
                <FriendlyAddress address={web3.connectedAccount} />
                <Typography>- ETH Balance: {_.get(userBalance, 'data.friendlyEthBalance', '...')}</Typography>
                <Typography>- OPUS Balance: {_getUserTokenBalance('OPUS')}</Typography>
                <Typography>- USDC Balance: {_getUserTokenBalance('USDC')}</Typography>
                {
                  !(isPending || isMining) && (
                    <>
                      <Typography> &nbsp; </Typography>
                      <Button color="primary" variant="outlined" onClick={_testTransaction}>Test TX</Button>
                    </>
                  )
                }
                {
                  (isPending || isMining) && (
                    <>
                      <Loading />
                    </>
                  )
                }
                {
                  lastMintedTokenId > 0 && (
                    <Typography>
                      - Newly Minted Token ID: {lastMintedTokenId}
                    </Typography>
                  )
                }
                <Typography>- TX Status: {_.get(txState, 'status', 'none')}</Typography>
                <Typography>- Last TX Hash: {_.get(txState, 'txData.hash', 'none')}</Typography>
              </>
            )
        }
      </>
    );
  };

  const _renderRegisteredWallet = () => {
    return (
      <>
        <Typography variant="h6">Registered Wallet:</Typography>
        <FriendlyAddress address={registeredWallet} />
      </>
    );
  };

  const _renderWalletRegistration = () => {
    if (!_.isEmpty(registeredWallet) || _.isEmpty(web3.connectedAccount)) { return; }

    if (isApiLoading) {
      return (
        <>
          <Typography variant="h6">Registering Wallet:</Typography>
          <Typography component="div">{web3.connectedAccount}</Typography><Loading />
        </>
      );
    }

    return (
      <>
        <Typography variant="h6">Register Connected Wallet:</Typography>
        <FriendlyAddress address={web3.connectedAccount} />
        <Button size="small" variant="contained" color="primary" onClick={_registerWallet}>Register Wallet</Button>
        <Button size="small" variant="outlined" color="primary" onClick={_disconnectWallet}>Disconnect Wallet</Button>
        <Typography variant="caption" component="div">This wallet will only be associated with your account for receiving Earnings and Rewards.</Typography>
      </>
    );
  };

  return (
    <>
      {_renderConnectionSteps()}
      {_renderRegisteredWallet()}
      {_renderConnectWallet()}
      {_renderWalletRegistration()}
    </>
  );
};

export { ConnectRegisteredWallet };
