import window from 'global';

// Common
import { GLOBALS } from '../utils/globals';
import { Helpers } from '../utils/helpers';

export const addWalletNetwork = async (chainId) => {
  const chainName = Helpers.getChainNameById(chainId);
  const publicRpcUrl = GLOBALS.NETWORK_FACTS[chainId].publicRpcUrl;

  const params = {
    chainId: Helpers.toHex(+chainId), // A 0x-prefixed hexadecimal string
    chainName: chainName,
    nativeCurrency: {
      name: GLOBALS.NETWORK_FACTS[chainId].nativeCurrency.name,
      symbol: GLOBALS.NETWORK_FACTS[chainId].nativeCurrency.symbol, // 2-6 characters long
      decimals: GLOBALS.NETWORK_FACTS[chainId].nativeCurrency.decimals,
    },
    rpcUrls: [ publicRpcUrl ], // must be an array
    blockExplorerUrls: [ GLOBALS.NETWORK_FACTS[chainId].blockExplorerUrl ], // must be an array
  };

  await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params:[ params ],
  });
};

export const switchWalletNetwork = async (chainId) => {
  const _switch = async () => {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: Helpers.toHex(+chainId) }],
    });
  };
  try {
    await _switch();
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await addWalletNetwork(chainId);
        await _switch();
      } catch (addError) {
        console.log(addError);
      }
    } else {
      console.log(switchError);
    }
  }
};
