// Frameworks
import React from 'react';

// Material UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// App Components
import SEO from '../components/seo';
import { Loading } from '../components/Loading';
import { AudioDisplay } from '../components/AudioDisplay';

// App Hooks
import { useMusic } from '../queries/useMusic';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] Music');
log.debug('initialized');


const Music = ({ musicId }) => {
  const { isLoading, data: music } = useMusic({ musicId });

  const _renderMusicFile = () => {
    if (isLoading) {
      return (
        <Loading />
      );
    }

    return (
      <Box m={2}>
        <Box sx={{ maxWidth: '80%', mx: 'auto' }}>
          <AudioDisplay musicId={musicId} />
        </Box>

        <Typography variant="h6">Audio:</Typography>
        <Typography>Music ID: {music.musicId}</Typography>
        {/* <Typography>FILE: {music.metadata.properties.audio.audioName}</Typography>
        <Typography>ID:   {music.metadata.properties.audio.storageRefId}</Typography>
        <Typography>URL:  {music.metadata.properties.audio.http}</Typography>
        <Typography variant="h6">Metadata:</Typography>
        <Typography>IPFS: {music.metadata.ipfs}</Typography>
        <Typography>HTTP: {music.metadata.http}</Typography>
        <Typography variant="h6">Cover Image:</Typography>
        <Typography>IPFS: {music.metadata.properties.coverImage.ipfs}</Typography>
        <Typography>HTTP: {music.metadata.properties.coverImage.http}</Typography>
        <Typography variant="h6">Status:</Typography>
        <Typography>Is Minted: {music.minted ? 'True' : 'False'}</Typography> */}
      </Box>
    );
  };

  return (
    <>
      <SEO title={'Music'} />

      <Typography variant="h6" align="center">Carbon Opus - Music</Typography>

      {_renderMusicFile()}
    </>
  );
};

export default Music;
