// Frameworks
import React from 'react';
import _ from 'lodash';

// Material UI
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';

// App Hooks
import { useMember } from '../../queries/useMember';
import { useAuthContext } from '../../contexts/auth';

// App Components
import { Loading } from '../Loading';
// import { GLOBALS } from '../../utils/globals';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Member/Details');
log.debug('initialized');

const _avatarDimensions = {
  width: { xs: 100, md: 120, lg: 140 },
  height: { xs: 100, md: 120, lg: 140 },
};

const MemberDetails = ({ memberId }) => {
  const [ authState ] = useAuthContext();
  const { isLoading, data: profileMember } = useMember(memberId);
  const theme = useTheme();

  const currentMemberId = _.get(authState, 'user.uid');
  const profileMemberId = profileMember?.memberId;
  const isProfileOwner = profileMemberId === currentMemberId;

  if (isLoading) {
    return (
      <Loading />
    );
  }

  const displayName = _.get(profileMember, 'displayName');
  const screenName = _.get(profileMember, 'screenName');

  return (
    <Paper elevation={0} sx={{ mb: 3 }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Paper variant="avatar" sx={{ mt: -6 }}>
              <Avatar variant="square" sx={_avatarDimensions}>
                {_.first(displayName)}
              </Avatar>
            </Paper>
          </Box>
          {
            isProfileOwner && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  size="small"
                  // variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                >
                  Edit
                </Button>
              </Box>
            )
          }
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ p: 1, pb: 2 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" justifyContent="flex-start" spacing={4}>
                <Stack direction="row" justifyContent="flex-start" spacing={1}>
                  <Typography variant="h5">{displayName}</Typography>
                  <VerifiedUserIcon color="primary" />
                </Stack>
                {(authState.isLoggedIn && !isProfileOwner) && <Button variant="outlined" size="small">Follow</Button>}
              </Stack>

              <Stack direction="row" justifyContent="flex-end" spacing={0}>
                <Tooltip title="Web"><IconButton aria-label="Web"><PublicIcon /></IconButton></Tooltip>
                <Tooltip title="Twitter"><IconButton aria-label="Twitter"><TwitterIcon /></IconButton></Tooltip>
                <Tooltip title="Facebook"><IconButton aria-label="Facebook"><FacebookIcon /></IconButton></Tooltip>
                <Tooltip title="Instagram"><IconButton aria-label="Instagram"><InstagramIcon /></IconButton></Tooltip>
                <Tooltip title="LinkedIn"><IconButton aria-label="LinkedIn"><LinkedInIcon /></IconButton></Tooltip>
                <Tooltip title="YouTube"><IconButton aria-label="YouTube"><YouTubeIcon /></IconButton></Tooltip>
              </Stack>
            </Stack>

            <Typography>@{screenName}</Typography>
            <Typography>Major Label Recording Artist, signed to Atlantic Records.</Typography>

            <Box sx={{ pt: 2 }}>
              <Stack direction="row" justifyContent="flex-start" spacing={4}>
                <Typography><strong style={{ color: theme.palette.primary.dark }}>213K</strong> Followers</Typography>
                <Typography><strong style={{ color: theme.palette.primary.dark }}>497K</strong> Monthly Listeners</Typography>
                <Typography><strong style={{ color: theme.palette.primary.dark }}>210M</strong> Total Plays</Typography>
              </Stack>

              <Stack direction="row" justifyContent="flex-start" spacing={4}>
                <Typography><strong>Genre:</strong> <strong style={{ color: theme.palette.primary.dark }}>Hip-Hop/Rap</strong></Typography>
                <Typography><strong>Member Since:</strong> May 30, 2019</Typography>
              </Stack>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export { MemberDetails };
