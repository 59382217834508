
import { QueryClient } from 'react-query';
// import * as _ from 'lodash';

// Singleton for accessing the QueryClient instance
class ReactQueryClient {
  static __instance = null;
  static instance() {
    if (!this.__instance) {
      this.__instance = new QueryClient({
        defaultOptions: {
          queries: {
            // globally default to 20 seconds
            staleTime: 1000 * 20,
          },
        },
      });
    }
    return this.__instance;
  }
}

const setQueryDefaults = (query, options) => {
  ReactQueryClient.instance().setQueryDefaults(query, options);
};

const invalidateQueries = (queryId) => {
  ReactQueryClient.instance().invalidateQueries(queryId);
};

const getState = (newState = {}) => {
  const defaultState = { isIdle: false, isLoading: false, isError: false, isSuccess: false, data: {} };
  return { ...defaultState, ...newState };
};

export default ReactQueryClient;
export {
  setQueryDefaults,
  invalidateQueries,
  getState,
};
