// Frameworks
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import _ from 'lodash';

// Material UI
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

// App Hooks
import { invalidateQueries } from '../../queries';
import { useMember } from '../../queries/useMember';
import { useAuthContext } from '../../contexts/auth';
import { useNftStorage } from '../../hooks/useNftStorage';

// App Components
import { Loading } from '../Loading';
import { UploadFiles } from './Files';
import { UploadDetails } from './Details';
import { UploadPricing } from './Pricing';
import { UploadReview } from './Review';
import { GLOBALS } from '../../utils/globals';

// Central Logging
import { Logger } from '../../utils/logger';
const log = Logger('Upload/Steps');
log.debug('initialized');


const steps = [
  'Select your Files',
  'Describe your Upload',
  'Setup Pricing',
  'Final Review',
];

const _getDeaultFormData = () => ({
  title:            '',
  artist:           '',
  hasCustomArtist:  false,
  desc:             '',
  genre:            '',
  subgenre:         '',
  audio:            '',
  audioSize:        '',
  audioMime:        '',
  audioBuffer:      null,
  image:            '',
  imageSize:        '',
  imageMime:        '',
  imageDimensions:  {},
  imageBuffer:      null,
  imageBase64:      null,
  hasBgColor:       false,
  bgColor:          '',
  attributes:       [],
  salePrice:        GLOBALS.SALE_PRICE_DEF,
  resaleRoyalty:    GLOBALS.RESALE_ROYALTY_DEF,
});

const UploadSteps = () => {
  const [ authState ] = useAuthContext();
  const { isLoading, data: currentMember } = useMember(authState?.user?.uid);
  const [ activeStep, setActiveStep ] = useState(0);
  const [ completeFormData, setCompleteFormData ] = useState(_getDeaultFormData());

  const {
    isNftLoading,
    isNftError,
    isNftSuccess,
    storeNFT,
    resetNFT,
    nftLoadingState,
    nftResult,
    nftErrors,
  } = useNftStorage();

  const handleNext = async () => {
    setActiveStep(activeStep + 1);
    if (activeStep === steps.length - 1) {
      if (!completeFormData.hasCustomArtist) {
        completeFormData.artist = currentMember?.memberId;
      }
      await storeNFT(completeFormData);
      invalidateQueries([ 'useMember' ]);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReset = () => {
    setCompleteFormData(_getDeaultFormData());
    resetNFT();
    setActiveStep(0);
  };

  const handleChange = step => ({ isValid, data }) => {
    // console.log({ step, isValid, data });
    setCompleteFormData((oldState) => {
      return {
        ...oldState,
        ...data,
        // isValid,
      };
    });
  };

  const _redirectToProfile = () => {
    navigate(`${GLOBALS.APP_ROOT}/profile`);
  };

  const _redirectToMusic = musicId => () => {
    navigate(`${GLOBALS.APP_ROOT}/music/${musicId}`);
  };

  const renderUploadStatus = () => {
    if (isNftLoading) {
      return (
        <Box sx={{ width: '60%', mx: 'auto' }}>
          <Typography variant="body1">Upload Progress</Typography>
          <Divider sx={{ mb: 2, mt: 1 }} />
          <Box sx={{ mb: 3 }}>
            <Typography variant="overline">Audio File</Typography>
            <LinearProgress variant="determinate" value={nftLoadingState.audio} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="overline">Cover Image</Typography>
            <LinearProgress variant="determinate" value={nftLoadingState.cover} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="overline">Thumbnail Image</Typography>
            <LinearProgress variant="determinate" value={nftLoadingState.thumb} />
          </Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="overline">Metadata</Typography>
            <LinearProgress variant="determinate" value={((nftLoadingState.meta + nftLoadingState.db) / 2)} />
          </Box>
        </Box>
      );
    }

    if (isNftError) {
      return (
        <Alert severity="error">
          <Typography variant="h6">Upload Error</Typography>
          <Typography>{JSON.stringify(nftErrors)}</Typography>
        </Alert>
      );
    }

    if (isNftSuccess) {
      return (
        <>
          <Typography variant="h4">Music Created!</Typography>
          <Box mb={2}>
            <Button size="small" variant="outlined" color="primary" onClick={_redirectToMusic(nftResult.musicId)}>View Music File</Button>
          </Box>
          <Box mb={2}>
            <Button size="small" variant="outlined" color="secondary" onClick={_redirectToProfile}>Return to Profile</Button>
          </Box>
        </>
      );
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <UploadFiles displayErrors={false} formData={completeFormData} onChange={handleChange(step)} />;
      case 1:
        return <UploadDetails displayErrors={false} formData={completeFormData} onChange={handleChange(step)} />;
      case 2:
        return <UploadPricing displayErrors={false} formData={completeFormData} onChange={handleChange(step)} />;
      case 3:
        return <UploadReview formData={completeFormData} />;
      case 4:
        return renderUploadStatus();
      default:
        throw new Error('Unknown step');
    }
  };

  const renderStepControls = (activeStep) => {
    if (activeStep === steps.length) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleReset}
            sx={{ mt: 3, ml: 1 }}
            disabled={!isNftSuccess}
          >
            New Upload
          </Button>
        </Box>
      );
    }

    let isDisabled = true;
    if (activeStep === 0 && completeFormData?.audioValid && completeFormData?.imageValid) {
      isDisabled = false;
    }
    if (activeStep === 1 && completeFormData?.titleValid && completeFormData?.genreValid) {
      isDisabled = false;
    }
    if (activeStep > 1) {
      isDisabled = false;
    }
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 3 }}>
        {activeStep !== 0 && (
          <Button onClick={handleBack} sx={{ ml: 1 }}>
            Back
          </Button>
        )}

        <Button
          variant="contained"
          onClick={handleNext}
          sx={{ ml: 1 }}
          disabled={isDisabled}
        >
          {activeStep === steps.length - 1 ? 'Upload' : 'Next'}
        </Button>
      </Box>
    );
  };


  if (isLoading) {
    return (
      <Loading />
    );
  }
  return (
    <form autoComplete="off">
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
        <Divider sx={{ mt: 5 }} />
        {renderStepControls(activeStep)}
      </Paper>
    </form >
  );
};

export { UploadSteps };
