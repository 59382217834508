// Frameworks
import React, { createContext, useContext, useReducer, useEffect, useRef, useCallback } from 'react';
import * as _ from 'lodash';
import window from 'global';

// App Components
// import { useLocation } from '../hooks/useLocation';
// import { GLOBALS } from '../utils/globals';

const calculateTime = (secs) => {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${minutes}:${returnedSeconds}`;
};


const initialState = {
  isFloatingPanelVisible: false,
  hasCurrent: false,
  current: {},  // music metadata
  meta: {},     // audio-file metadata
  isPlaying: false,
  seekPosition: 0,
  forceSeek: 0,
  volume: 80,
};
export const AudioContext = createContext(initialState);

export function useAudioContext() {
  return useContext(AudioContext);
}


const AudioReducer = (state, action) => {
  let tmp;
  switch (action.type) {
    case 'TOGGLE_PANEL':
      return {
        ...state,
        isFloatingPanelVisible: action.payload,
      };
    case 'CURRENT_AUDIO':
      return {
        ...state,
        current: action.payload,
        hasCurrent: !_.isEmpty(action.payload),
        isPlaying: false,
        meta: {},
        forceSeek: 0,
        seekPosition: 0,
      };
    case 'UPDATE_VOLUME':
      return {
        ...state,
        volume: action.payload,
      };
    case 'UPDATE_META':
      return {
        ...state,
        meta: action.payload,
      };
    case 'UPDATE_PLAYING':
      tmp = state.isFloatingPanelVisible;
      if (action.payload && !tmp) {
        tmp = true;
      }
      return {
        ...state,
        isPlaying: action.payload,
        isFloatingPanelVisible: tmp,
      };
    case 'SEEK_POSITION':
      return {
        ...state,
        seekPosition: action.payload,
      };
    case 'FORCE_SEEK':
      return {
        ...state,
        forceSeek: action.payload,
        seekPosition: action.payload,
      };
    default:
      return state;
  }
};

export default function Provider({ children }) {
  const [ state, dispatch ] = useReducer(AudioReducer, initialState);
  const audioRef = useRef();

  const _getCurrentAudioFile = useCallback(() => {
    if (!state.hasCurrent) { return null; }
    return _.get(state, 'current.metadata.properties.audio.http', null);
  }, [ state ]);

  return (
    <AudioContext.Provider value={[ state, dispatch, audioRef ]}>
      <audio id="primary-audio" type="audio" ref={audioRef} preload="metadata" src={_getCurrentAudioFile()} />
      {children}
    </AudioContext.Provider>
  );
}

export function Updater() {
  const [ audio, audioDispatch, audioRef ] = useAudioContext();

  // Handle HTML5 Audio Element Events
  useEffect(() => {
    const audioEl = audioRef.current;

    const _getMetadata = () => {
      return {
        duration: audioEl.duration,
        durationStr: calculateTime(audioEl.duration),
        bufferedAmount: Math.floor(audioEl.buffered.end(audioEl.buffered.length - 1)),
        seekableAmount: Math.floor(audioEl.seekable.end(audioEl.seekable.length - 1)),
      };
    };

    const _handleMetadata = () => {
      audioDispatch({ type: 'UPDATE_META', payload: _getMetadata() });
    };

    const _handleTimeUpdate = () => {
      const payload = Math.floor(audioEl.currentTime);
      audioDispatch({ type: 'SEEK_POSITION', payload });
    };

    const _handleEnded = () => {
      audioEl.load(); // reset audio
      audioDispatch({ type: 'UPDATE_PLAYING', payload: false });
    };

    if (audioEl) {
      audioEl.addEventListener('loadedmetadata', _handleMetadata);
      audioEl.addEventListener('timeupdate', _handleTimeUpdate);
      audioEl.addEventListener('progress', _handleMetadata);
      audioEl.addEventListener('ended', _handleEnded);
    }
    return () => {
      audioEl.removeEventListener('loadedmetadata', _handleMetadata);
      audioEl.removeEventListener('timeupdate', _handleTimeUpdate);
      audioEl.removeEventListener('progress', _handleMetadata);
      audioEl.removeEventListener('ended', _handleEnded);
    };
  }, [ audioRef, audioDispatch ]);

  // Play/Pause
  useEffect(() => {
    const audioEl = audioRef.current;
    if (audio.hasCurrent) {
      if (audio.isPlaying) {
        audioEl.play();
      } else {
        audioEl.pause();
        audioDispatch({ type: 'FORCE_SEEK', payload: audioEl.currentTime });
      }
    }
  }, [ audio.hasCurrent, audio.isPlaying, audioRef, audioDispatch ]);

  // Seek-Position
  useEffect(() => {
    const audioEl = audioRef.current;
    if (audio.hasCurrent && audio.isPlaying && audio.forceSeek > 0 && audioEl.currentTime !== audio.forceSeek) {
      audioEl.currentTime = audio.forceSeek;
    }
  }, [ audio.hasCurrent, audio.isPlaying, audio.forceSeek, audioRef ]);

  // Volume
  useEffect(() => {
    const audioEl = audioRef.current;
    if (audioEl.volume !== audio.volume / 100) {
      audioEl.volume = audio.volume / 100;
    }
  }, [ audio.volume, audioRef ]);

  // Implementation of the Media Session API
  useEffect(() => {
    const audioEl = audioRef.current;
    if (audio.hasCurrent && 'mediaSession' in window.navigator) {
      const music = audio?.current ?? {};
      const imgSrc = _.get(music, 'metadata.thumbnail.http', _.get(music, 'metadata.properties.coverImage.http', ''));

      window.navigator.mediaSession.metadata = new MediaMetadata({
        title: music?.title ?? '',
        artist: music?.artist ?? '',
        album: music?.album ?? '',
        artwork: [
          { src: imgSrc, sizes: '200x200', type: 'image/jpeg' },
        ],
      });
      window.navigator.mediaSession.setActionHandler('play', () => {
        audioDispatch({ type: 'UPDATE_PLAYING', payload: true });
      });
      window.navigator.mediaSession.setActionHandler('pause', () => {
        audioDispatch({ type: 'UPDATE_PLAYING', payload: false });
      });
      window.navigator.mediaSession.setActionHandler('stop', () => {
        audioEl.load(); // reset audio
        audioDispatch({ type: 'UPDATE_PLAYING', payload: false });
      });
      window.navigator.mediaSession.setActionHandler('seekbackward', (details) => {
        const time = audioEl.currentTime - (details.seekOffset || 10);
        audioDispatch({ type: 'FORCE_SEEK', payload: time });
      });
      window.navigator.mediaSession.setActionHandler('seekforward', (details) => {
        const time = audioEl.currentTime + (details.seekOffset || 10);
        audioDispatch({ type: 'FORCE_SEEK', payload: time });
      });
      window.navigator.mediaSession.setActionHandler('seekto', (details) => {
        if (details.fastSeek && 'fastSeek' in audioEl) {
          audioEl.fastSeek(details.seekTime);
          return;
        }
        audioDispatch({ type: 'FORCE_SEEK', payload: details.seekTime });
      });
    }
  }, [ audio.hasCurrent, audio.current, audioRef, audioDispatch ]); // eslint-disable-line

  return null;
}
