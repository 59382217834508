// Frameworks
import React from 'react';

// Material UI
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// App Components
import { AudioPlayerFloat } from '../components/AudioPlayerFloat';
import { HeaderBar } from '../components/HeaderBar';
import { Footer } from '../components/Footer';
import { GLOBALS } from '../utils/globals';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('AppLayout');
log.debug('initialized');

function AppLayout({ location, children }) {
  return (
    <>
      <CssBaseline />
      <div>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <HeaderBar location={location} />
          </Grid>
          <Grid item>
            <Container fixed maxWidth="lg">
              {children}
            </Container>
          </Grid>
          <Grid item>
            <Footer />
          </Grid>
        </Grid>
      </div>
      {!GLOBALS.IS_PRE_LAUNCH && <AudioPlayerFloat />}
    </>
  );
}

export default AppLayout;
