// Frameworks
import { useQuery } from 'react-query';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from '../queries';
import { getMemberPlaylists } from '../firebase/getMemberPlaylists';


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query
const queryFn = memberId => async () => {
  return _transformResponse(await getMemberPlaylists({ memberId }), { memberId });
};

// Custom Hook
const useMemberPlaylists = ({ memberId }) => {
  const enabled = !_.isEmpty(memberId);
  return useQuery(
    [ 'memberPlaylists', memberId ],
    queryFn(memberId),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformResponse = (response, extraData) => {
  return { ..._.get(response, 'data.data'), ...extraData };
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time
setQueryDefaults([ 'memberPlaylists' ], { staleTime: 60 * 1000 * 60 }); // 60 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'memberPlaylists' ])


// Export Hook
export { useMemberPlaylists };
