// Frameworks
import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import axios from 'axios';
import * as _ from 'lodash';

// App Components
import { setQueryDefaults } from './index';
import { getRpcUrl } from '../web3/getRpcUrl';
import { GLOBALS } from '../utils/globals';

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Hook
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Custom Query Function
const queryFn = (chainId, userAddress, tokenAddresses) => async () => {
  const endpoint = getRpcUrl(chainId, 'alchemy');
  const operatorAddress = GLOBALS.CONTRACT_ADDRESS.CARBON_OPUS[chainId];
  const results = await Promise.all(_.map(tokenAddresses, (tokenAddress) => {
    const data = JSON.stringify({
      'jsonrpc': '2.0',
      'method': 'alchemy_getTokenAllowance',
      'params': [{ 'contract': tokenAddress, 'owner': userAddress, 'spender': operatorAddress }],
      'id': tokenAddress,
    });
    return axios({
      method: 'post',
      url: endpoint,
      headers: { 'Content-Type': 'application/json' },
      data,
    });
  }));
  return _transformTokenData(results, chainId);
};

// Custom Hook
const useUserTokenAllowances = (userAddress, tokenAddresses, chainId) => {
  const enabled = !_.isEmpty(userAddress) && !_.isEmpty(tokenAddresses) && !!chainId;
  const tokensHash = ethers.utils.id(JSON.stringify(tokenAddresses));
  return useQuery(
    [ 'userTokenAllowances', chainId, userAddress, tokensHash ],
    queryFn(chainId, userAddress, tokenAddresses),
    { enabled },
  );
};


// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Transformations
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const _transformTokenData = (results, chainId) => {
  return _.map(results, resultData => ({
    tokenAddress: resultData.data.id,
    allowance: resultData.data.result,
    chainId,
  }));
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Query Configs
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Set Stale-Time for Aave Protocol Data
setQueryDefaults([ 'userTokenAllowances' ], { staleTime: 3 * 1000 * 60 }); // 3 Mins

// NOTE:
// invalidate the cache via:
//   ReactQueryClient.instance().invalidateQueries([ 'userTokenAllowances' ])


// Export Hook
export { useUserTokenAllowances };
